import React from 'react'
// import {PriceBubble} from '../../img'
import {ReactFitty} from 'react-fitty'
import {useNavigate} from 'react-router-dom'
// import {useFormikContext} from 'formik'
const TierRow = ({tier, description, features, image, priceClass, mostPopular}) => {
	return (
		<>
			{mostPopular && <h5 className='most-popular absolute -top-6 mt-auto py-1 px-2 bg-doodle text-offwhite rounded text-xs'>Most Popular</h5>}
			<div className='tier-name-container'>
				<ReactFitty className='tier-name'>{tier}</ReactFitty>
				{/* <div className='tier-name'>{tier}</div> */}
			</div>
			<div className='image'>
				<img src={image} alt={tier} />
			</div>
			{/* <div className='description py-4'>{description}</div> */}
			<div className='mt-auto'>
				<ul className='features'>
					{features.map((feature, i) => (
						<li key={i}>
							<span className='checkmark'>&#10003;</span>
							{feature}
						</li>
					))}
				</ul>
				{/* <div className='price'>
					<PriceBubble className={priceClass} />
				</div> */}
				<div className='button-container pt-4'>
					<TierButton tier={tier} />
				</div>
			</div>
		</>
	)
}
const TierButton = ({tier}) => {
	const navigate = useNavigate()
	return (
		<a href={`#${tier}`} className='' onClick={() => navigate('/order')}>
			Get now!
		</a>
	)
}
const TierComparisonTable = ({tableData}) => {
	return (
		<div className='tier-comparison-table'>
			{Object.values(tableData).map((data) => (
				<div className='tier-row' key={data.name}>
					<TierRow
						tier={data.name}
						description={data.description}
						features={data.features}
						image={data.img}
						priceClass={data.priceClass}
						mostPopular={data.most_populer}
					/>
				</div>
			))}
		</div>
	)
}

export default TierComparisonTable
