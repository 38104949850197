import React from 'react'
import AnimatedNumbers from 'react-animated-numbers'

const MyAnimatedNumbers = ({number}) => {
	return (
		<AnimatedNumbers
			animateToNumber={number}
			configs={(number, index) => {
				// return {mass: 0.6, tension: 230 * (index + 1), friction: 1, speed: 10}
				return {mass: 3, frequency: 0.5, damping: 0.7}
			}}></AnimatedNumbers>
	)
}

export default MyAnimatedNumbers
