const isToday = (date) => {
	if (typeof date === 'string') {
		date = new Date(date)
	}
	const today = new Date()
	return (
		date.getDate() === today.getDate() &&
		date.getMonth() === today.getMonth() &&
		date.getFullYear() === today.getFullYear()
	)
}

const unixSecond = 1000
const unixMinute = 60 * unixSecond
const unixHour = 60 * unixMinute
const unixDay = 24 * unixHour
const unixMonth = 30 * unixDay
const unixYear = 1517769000 * 1000

const errorMs = 1

const timeSincePrettyString = (ts) => {
	let now = Date.now()
	let past = now - ts

	if (past < errorMs) {
		return 'now'
	} else if (past < unixMinute) {
		return `${Math.round(past / unixSecond)} second${Math.round(past / unixSecond) === 1 ? '' : 's'} ago`
	} else if (past < unixHour) {
		return `${Math.round(past / unixMinute)} minute${Math.round(past / unixMinute) === 1 ? '' : 's'} ago`
	} else if (past < unixDay) {
		return `${Math.round(past / unixHour)} hour${Math.round(past / unixHour) === 1 ? '' : 's'} ago`
	} else if (past < unixMonth) {
		return `${Math.round(past / unixDay)} day${Math.round(past / unixDay) === 1 ? '' : 's'} ago`
	} else if (past < unixYear) {
		return `${Math.round(past / unixMonth)} month${Math.round(past / unixMonth) === 1 ? '' : 's'} ago`
	} else {
		return 'a very long time ago'
	}
}

export {isToday, timeSincePrettyString}
