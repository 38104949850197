import React, {useState} from 'react'
import axios from 'axios'
import {apiBaseUrl} from '../../utils/config'
import {useNotification} from '../../context/NotificationContext'

function MessageSender() {
	const [subject, setSubject] = useState('')
	const [name, setName] = useState('')
	const [email, setEmail] = useState('')
	const [message, setMessage] = useState('')
	const [imageFiles, setImageFiles] = useState([])
	const {setToast} = useNotification()

	const handleSubjectChange = (event) => {
		setSubject(event.target.value)
	}

	const handleNameChange = (event) => {
		setName(event.target.value)
	}

	const handleEmailChange = (event) => {
		setEmail(event.target.value)
	}

	const handleMessageChange = (event) => {
		setMessage(event.target.value)
	}

	const handleImageChange = (event) => {
		setImageFiles(event.target.files)
	}

	const handleUpload = () => {
		// Create a FormData object to send the data as multipart/form-data
		const formData = new FormData()
		formData.append('subject', subject)
		formData.append('name', name)
		formData.append('email', email)
		formData.append('message', message)

		// Append the uploaded image files to the FormData object
		for (let i = 0; i < imageFiles.length; i++) {
			formData.append('image', imageFiles[i])
		}

		// Send a POST request to the server to submit the message and image files
		axios
			.post(`${apiBaseUrl}/contact`, formData)
			.then((response) => {
				console.log(response.data)
				// Clear the form inputs and uploaded files
				setSubject('')
				setName('')
				setEmail('')
				setMessage('')
				setImageFiles([])
				setToast('message sent!')
			})
			.catch((error) => {
				console.error(error)
			})
	}

	return (
		<div className='flex-to-center flex-col'>
			<label>Subject:</label>
			<input type='text' value={subject} onChange={handleSubjectChange} />

			<label>Name:</label>
			<input type='text' value={name} onChange={handleNameChange} />

			<label>Email:</label>
			<input type='email' value={email} onChange={handleEmailChange} />

			<label>Message:</label>
			<textarea value={message} onChange={handleMessageChange} />

			<div>
				<input type='file' required multiple onChange={handleImageChange} />
				<button onClick={handleUpload}>Upload</button>
			</div>
		</div>
	)
}

export default MessageSender
