import React from 'react'
import ReactGA from 'react-ga4'

// import './footer.css'
// import {CopyCop, FiverrIcon} from '../../img'
import { CopyCop } from '../../img'
import { FaInstagram, FaTelegram } from 'react-icons/fa' // these are icons from FontAwesome

// import DividerWave from '../../components/divider_wave/divider_wave'
import { useWhyDidYouUpdate } from '../../utils/useWhyDidYouUpdate'
import { Link } from 'react-router-dom'

const FooterLink = ({ link, Icon }) => {
	return (
		<a href={link} target='_blank' rel='noopener noreferrer' className='text-gray-300 hover:text-gray-400 mx-2'>
			<Icon size={20} />
		</a>
	)
}

const social_links = {
	tg: { link: 'https://t.me/AdelezCartoon', handle: 'AdelezCartoon', icon: FaTelegram },
	ig: { link: 'https://instagram.com/knappstudio.cartoons', handle: 'knappstudio.cartoons', icon: FaInstagram },
}

// import { isChrome} from '../../util/browserCheck'
const Footer = (props) => {
	useWhyDidYouUpdate('footer', props)
	// const d = new Date()
	// const currentYear = d.getFullYear()
	return (
		// TODO return sticky bottom-0 z-footer later
		<footer className='pt-divider'>
			{/* <DividerWave direction='down' /> */}
			<div className='flex items-center p-4 sticky bottom-0'>
				<div className='img'>
					<CopyCop className='fill-white w-1/2 h-full doodle' />
				</div>
				<div className='flex justify-center mt-2 social-buttons'>
					<div className='text-gray-300 text-sm px-6'>Follow us on social media to stay up to date on new caricatures and specials!</div>
					<div className='flex p-4'>
						<FooterLink link={social_links.tg.link} Icon={social_links.tg.icon} />
						<FooterLink link={social_links.ig.link} Icon={social_links.ig.icon} />
						{/* <FooterLink link={social_links.ig.link} Icon={FaTwitter} /> */}
						{/* <FooterLink link={'https://fiverr.com/' + ig_handle} Icon={FiverrIcon} /> */}
					</div>
				</div>
			</div>
			<div className='copyright text-sm my-2 p-4 flex-to-center flex-col'>
				<p className='text-gray-300'>Copyright &copy; {new Date().getFullYear()} Adelez Cartooning Studio</p>
				<p className='text-gray-400'>
					<Link to='/terms' className='px-4'>
						Terms of Service
					</Link>
					|
					<Link to='/privacy' className='px-4'>
						Privacy Policy
					</Link>
				</p>
			</div>
		</footer>
	)
}

export default Footer
