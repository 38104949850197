import {useContext, useEffect, useState} from 'react'
import {OrderContext} from './OrderPage'

const PeopleCounter = ({header}) => {
	const {currentOrder, setCurrentOrder, formik} = useContext(OrderContext)
	const [peopleCount, setPeopleCount] = useState(currentOrder.people.amount)

	const handleCountIncrease = () => {
		setPeopleCount(peopleCount + 1)
	}

	const handleCountDecrease = () => {
		if (peopleCount > 1) {
			setPeopleCount(peopleCount - 1)
		}
	}
	useEffect(() => {
		if (peopleCount < 1) setPeopleCount(1)
		setCurrentOrder({...currentOrder, people: {amount: peopleCount}})

		formik.setFieldValue('people', peopleCount)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [peopleCount])

	return (
		// <div className='flex justify-center'>
		<div className='flex flex-col pt-4'>
			<label htmlFor='pplCount' className='mt-8'>
				{header}
			</label>

			<div className='mx-auto mt-2'>
				<button type='addPpl' onClick={handleCountDecrease} className='border border-gray-400 rounded-md p-2 hover:outline-none bg-blue-400 text-white'>
					-
				</button>
				<input
					id='pplCount'
					type='number'
					value={peopleCount}
					min={1}
					max={10}
					onChange={(e) => setPeopleCount(Number(e.target.value))}
					className='mx-2 border border-gray-400 p-2 rounded-md text-center w-16'
				/>
				<button type='rmPpl' onClick={handleCountIncrease} className='border border-gray-400 rounded-md p-2 hover:outline-none bg-blue-400 text-white'>
					+
				</button>
			</div>
		</div>
	)
}
export default PeopleCounter
