// eslint-disable-next-line no-unused-vars
import { useContext, useMemo } from 'react'

import { simpleGalleryImages, standardGalleryImages, premiumGalleryImages, customGalleryImages } from '../../img'
import { shuffleArray } from '../../utils'

const shuffledSimple = shuffleArray(simpleGalleryImages)
const shuffledStandard = shuffleArray(standardGalleryImages)
const shuffledPremium = shuffleArray(premiumGalleryImages)
const shuffledCustom = shuffleArray(customGalleryImages)

export const OrderOptions = {
	style: [
		{
			price: 20,
			slug: 'lineart',
			image: shuffledSimple[1],
			name: 'Line Art',
			description: 'Line art is a simple, black and white style.',
		},
		{
			price: 30,
			image: shuffledStandard[1],
			slug: 'watercolor',
			name: 'Watercolor',
			description: 'Watercolor is a soft, colorful style.',
		},
		{
			price: 40,
			image: shuffledPremium[1],
			slug: 'painterly',
			name: 'Painterly',
			description: 'Painterly is a detailed, brush stroke style.',
		},
	],
	detail: [
		{
			price: 10,
			slug: 'head',
			image: shuffledSimple[2],
			name: 'Head',
			description: 'Head Only',
		},
		{
			price: 15,
			slug: 'headAndShoulders',
			image: shuffledStandard[2],
			description: 'Head And Shoulders...',
			name: 'Head And Shoulders',
		},
		{
			price: 20,
			slug: 'fullBody',
			image: shuffledPremium[2],
			name: 'Full Body',
			description: 'Full Body, with or without a pose',
		},
	],
	background: [
		// {
		// 	price: 10,
		// 	slug: 'color',
		// 	image: shuffledStandard[3],
		// 	name: 'Simple Color',
		// 	description: 'Watercolor is a soft, colorful style.',
		// },
		{
			price: 0,
			slug: 'transparent',
			image: shuffledPremium[3],
			name: 'Transparent',
			description: 'Painterly is a detailed, brush stroke background.',
		},
		{
			price: 5,
			slug: 'canvas',
			image: shuffledSimple[3],
			name: 'Canvas',
			description: 'Line art is a simple, black and white style.',
		},
		{
			price: 15,
			slug: 'simpleDetail',
			image: shuffledStandard[3],
			name: 'Simple Detail',
			description: 'Painterly is a detailed, brush stroke background.',
		},
		{
			price: 20,
			slug: 'complexDetail',
			image: shuffledCustom[2],
			name: 'Complex Detail',
			description: 'Painterly is a detailed, brush stroke background.',
		},
	],
	wait: [
		{ price: 25, slug: 'twoDays', name: '2 Days.', description: 'I need this ASAP!' },
		{ price: 10, slug: 'sevenDays', name: '7 Days.', description: 'I can wait a week :)' },
		{ price: 0, slug: 'noRush', name: 'No rush.', description: "I've got time." },
	],
}

export const defaultOrder = {
	style: { price: OrderOptions.style[0].price, slug: OrderOptions.style[0].slug },
	detail: { price: OrderOptions.detail[0].price, slug: OrderOptions.detail[0].slug },
	background: { price: OrderOptions.background[0].price, slug: OrderOptions.background[0].slug },
	wait: { price: 0, slug: 'noRush' },
	quote: '',
	email: '',
	extra: '',
	people: { amount: 1 },
	total: 0,
}
export const standardOrder = {
	style: { price: OrderOptions.style[1].price, slug: OrderOptions.style[1].slug },
	detail: { price: OrderOptions.detail[1].price, slug: OrderOptions.detail[1].slug },
	background: { price: OrderOptions.background[1].price, slug: OrderOptions.background[1].slug },
	wait: { price: 0, slug: 'sevenDays' },
	quote: '',
	email: '',
	extra: '',
	people: { amount: 1 },
	total: 0,
}
export const premiumOrder = {
	style: { price: OrderOptions.style[2].price, slug: OrderOptions.style[2].slug },
	detail: { price: OrderOptions.detail[2].price, slug: OrderOptions.detail[2].slug },
	background: { price: OrderOptions.background[2].price, slug: OrderOptions.background[2].slug },
	wait: { price: 0, slug: 'sevenDays' },
	quote: '',
	email: '',
	extra: '',
	people: { amount: 1 },
	total: 0,
}
