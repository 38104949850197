import React, {useEffect, useState} from 'react'
import ReactDOM from 'react-dom'
import {useSpring, animated} from '@react-spring/web'
import {FaTimes} from 'react-icons/fa'

function FullScreenImageViewer({srcLarge, alt, onClose, style}) {
	useEffect(() => {
		// Prevent scrolling on the background layer
		document.body.style.overflow = 'hidden'

		// Re-enable scrolling when the component unmounts
		return () => {
			document.body.style.overflow = ''
		}
	}, [])
	return (
		<div className='fixed top-0 left-0 w-full h-full bg-black flex items-center justify-center z-50 cursor-zoom-out' onClick={onClose}>
			<img src={srcLarge} alt={alt} className='w-auto h-auto max-h-full' />
			<div className='absolute top-0 right-0 p-4 cursor-pointer text-white font-bold text-lg bg-black bg-opacity-75 rounded-bl-xl' onClick={onClose}>
				<FaTimes />
			</div>
		</div>
	)
}

function ZoomableImage({srcSmall, srcLarge, alt, className, zoomOutShowTip, style, ...rest}) {
	const [isFullScreen, setIsFullScreen] = useState(false)

	const handleImageClick = () => {
		setIsFullScreen(true)
	}

	const handleCloseClick = () => {
		setIsFullScreen(false)
		zoomOutShowTip()
	}

	const animationProps = useSpring({
		transform: isFullScreen ? 'scale(1.2)' : 'scale(1)',
		cursor: isFullScreen ? 'zoom-out' : 'zoom-in',
	})
	const animatedStyle = useSpring({
		...animationProps,
		...style,
	})

	return (
		<>
			<animated.img {...rest} src={srcSmall} alt={alt} onClick={handleImageClick} className={className} style={animatedStyle} />
			{isFullScreen && ReactDOM.createPortal(<FullScreenImageViewer srcLarge={srcLarge} onClose={handleCloseClick} />, document.body)}
		</>
	)
}

export default ZoomableImage
