export const tagOptions = [
	{
		label: 'Style',
		options: [
			{ label: 'Head', value: 'head' },
			{ label: 'Head & Shoulders', value: 'head-and-shoulders' },
			{ label: 'Full Body', value: 'full-body' },
		],
	},
	{
		label: 'Level of Detail',
		options: [
			{ label: 'Line Art', value: 'line-art' },
			{ label: 'Water Color', value: 'water-color' },
			{ label: 'Painterly', value: 'painterly' },
		],
	},
	{
		label: 'Background',
		options: [
			{ label: 'Transparent', value: 'transparent' },
			{ label: 'Canvas', value: 'canvas' },
			{ label: 'Simple Detail', value: 'simple-detail' },
			{ label: 'Complex Detail', value: 'complex-detail' },
		],
	},
	{
		label: 'Tier',
		options: [
			{ label: 'Simple', value: 'simple' },
			{ label: 'Standard', value: 'standard' },
			{ label: 'Premium', value: 'premium' },
			{ label: 'Custom', value: 'custom' },
		],
	},
	// {label: 'With Qoute', value: 'qoute'},

	{
		label: 'Quote',
		options: [
			{ label: 'With Qoute', value: 'qoute' },
			{ label: 'No Qoute', value: 'noqoute' },
		],
	},
	{
		label: 'People',
		options: [
			{ label: '1 Person', value: '1-person' },
			{ label: '2 People', value: '2-people' },
			{ label: '3+ People', value: '3-plus-people' },
			// {label: '10+ People', value: '10-plus-people'},
		],
	},
]
const flattenedOptions = {}
tagOptions.forEach((group) => {
	group.options
		? group.options.forEach((option) => {
			flattenedOptions[option.value] = option.label
		})
		: (flattenedOptions[group.value] = group.label)
})

export const flatTagOptions = flattenedOptions
