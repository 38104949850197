// eslint-disable-next-line
import {useEffect, useCallback, useState} from 'react'
import {useEventListener} from '../../utils/useEventListener'
// eslint-disable-next-line
import {debounce} from 'lodash'

export const ProgressBar = () => {
	const [scrollPos, setScrollPos] = useState(0)

	const getDocHeight = () => {
		return Math.max(
			document.body.scrollHeight,
			document.documentElement.scrollHeight,
			document.body.offsetHeight,
			document.documentElement.offsetHeight,
			document.body.clientHeight,
			document.documentElement.clientHeight
		)
	}
	// eslint-disable-next-line
	const [windowHeight, setWindowHeight] = useState(window.innerHeight)
	// eslint-disable-next-line
	const [docHeight, setDocHeight] = useState(getDocHeight())

	const scrollChangeHandler = useCallback(() => {
		requestAnimationFrame(() => {
			const scrollTop = window.pageYOffset // how much the user has scrolled by
			const totalDocScrollLength = docHeight - windowHeight
			const scrollPostion = Math.floor((scrollTop / totalDocScrollLength) * 100)

			setScrollPos(scrollPostion)
			// 	setScrollPos((window.pageYOffset / (document.body.offsetHeight - window.innerHeight)).toFixed(4) * 100 + '%')
		})
	}, [windowHeight, docHeight])

	const resizeHandler = debounce(
		useCallback(() => {
			console.log('resizeHandler', window.innerHeight, 'x', window.innerWidth)
			setWindowHeight(window.innerHeight)
			setDocHeight(getDocHeight)
		}, [setWindowHeight, setDocHeight]),
		200000
	)

	// Add event listener using our hook
	useEventListener('scroll', scrollChangeHandler)
	useEventListener('resize', resizeHandler)

	// useEffect(() => {
	// 	// document.body.style.setProperty('--scroll', scrollPos)
	// 	return () => {
	// 		// scrollChangeHandler.cancel()
	// 		// resizeHandler.cancel()
	// 		// window.onscroll = null
	// 		// window.removeEventListener('scroll', 'scroll', () => changeScrollPos, false)
	// 	}
	// }, [scrollChangeHandler, resizeHandler])

	return <div className='progress' style={{width: scrollPos + '%'}}></div>
}

// import React, {Component} from 'react'
// export default class App extends Component {
// state = {
// 	scrollPostion: 0,
// }
// componentDidMount() {
// 	this.listenToScrollEvent()
// }
//   <Progress scroll={this.state.scrollPostion + '%'} />
// }
