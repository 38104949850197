import {Link} from 'react-router-dom'

export const TermsAndPrivacy = () => {
	return (
		<div className='flex-to-center flex-col justify-center items-center p-4 h-[100vh]'>
			<div className='max-w-3xl'>
				<h1 className='font-adelez flex-to-center justify-center items-center text-2xl'>Terms & Privacy</h1>
				<div className='flex flex-col p-4'>
					<div className='p-4'>
						<h3>Analytics:</h3>
						<p>
							We use Google Analytics to monitor website performance. See{' '}
							<a href='https://analytics.google.com' className='underline text-orange'>
								analytics.google.com
							</a>{' '}
							for more information about their data policy.
						</p>
					</div>
					<div className='p-4'>
						<h3>Info you provide:</h3>
						<p>
							We don't sell your information, we only use information you provide to try to give you the best expirience with getting your own charicature
						</p>
					</div>
					<div className='p-4'>
						<h3>Other:</h3>
						<p>
							if there are any concerns related to terms or privacy, please reach out to use via the{' '}
							<Link to='/#contact' className='underline text-orange'>
								contact
							</Link>{' '}
							page.
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}
