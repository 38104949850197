import {flatTagOptions} from '../containers/Admin/TagsOptions'

export const TagsList = ({tags}) => {
	// console.log('taglist prep')
	if (!Array.isArray(tags)) {
		console.log('taglist is not an array')
		return tags
	}
	// console.log(flatTagOptions)
	// const tagLabels = tags.map((tagValue) => flattenedOptions[tagValue] || tagValue)
	const tagLabels = tags.map((tagValue) => {
		const tagLabel = flatTagOptions[tagValue] || tagValue
		// console.log(`Tag value: ${tagValue}, Tag label: ${tagLabel}`)
		return tagLabel
	})

	// const tagLabels = getTagLabels(tags, tagOptions)
	return (
		<ul className='pt-0'>
			{tagLabels.map((tag) => (
				<li key={tag}>{tag}</li>
			))}
		</ul>
	)
}
