import React from 'react'
import DividerWave from '../../../components/divider_wave/divider_wave'
// import './about1.css'
// eslint-disable-next-line
import {AboutPic, TriangleBG, AKCariSitting, DoodleOnText} from '../../../img'
import ScrollToTop from '../../../utils/scrollToTop'
// import {Title} from '../../components'
import {useWhyDidYouUpdate} from '../../../utils/useWhyDidYouUpdate'
// import {isiOS} from '../../../utils/browserCheck'
// import DividerWave from '../../components/divider_wave/divider_wave'
// const aboutText1 = `Adele Knapp, successful character designer and cartoonist.
//     <br/>How to figure out what to gift a friend for his/her birthday, a college, a boss, your friend who just got married, etc.?
//     <br/>Luckily, you can always rely on a fun lighthearted stylized caricature! Update your social media, surprise a friend or a family member with a unique, specialized unforgettable gift. They’ll love it!<br/>so if you are someone who has a face, or want to surprise another someone who has a face, get a quality caricature for your social media avatar needs and bring your personal character to life!
//     <br/>After all, nothing beats the creative touch of a professional cartoonist!`
const aboutText = `As a successful character designer and cartoonist, Adele Knapp understands the importance of finding a personalized gift for any occasion. Whether it's a birthday, graduation, or a wedding, a fun and lighthearted caricature can be the perfect solution.
    <br/>Not only can caricatures make for great gifts, but they can also be used to update your social media presence and bring your personal character to life. And when it comes to creating high-quality caricatures, nothing beats the creative touch of a professional cartoonist like Adele.
    <br/>So if you're looking for a unique and unforgettable gift for a friend or family member, or simply want to add some personality to your social media profile, consider getting a caricature from Adele. Whether you want to surprise someone with a personalized design or simply want to bring your own face to life, Adele has the skills and experience to help you achieve your goals.`
const About = (props) => {
	useWhyDidYouUpdate('About', props)
	const {id, forwardRef} = props
	// const triangle_link = 'bg-[url(' + TriangleBG + ')]'
	// console.log(triangle_link)
	return (
		<section
			className='relative bg-fixed bg-origin-border bg-main bg-left bg-cover bg-no-repeat'
			// style={{ backgroundImage: `url(${TriangleBG})` }}
			// style={{
			// 	backgroundImage: `${isiOS ? '' : `url(${TriangleBG})`}`,
			// }}
			id={id}
			data-section={id}
			ref={forwardRef}>
			<div className='h-navHeight bg-orange bg-opacity-50 fixed top-0 w-full shadow-md z-10'></div>

			<ScrollToTop />

			<div className='flex-to-center md:text-2xl relative px-6 py-24 z-0 '>
				<div className={`flex flex-col justify-center content-center max-w-4xl m-auto`}>
					<div className='doodleOnTitle m-auto'>
						<DoodleOnText className='p-0 w-full' />
					</div>
					<div className='textAndProfile flex md:inline flex-col md:flex-row items-center'>
						<img
							className=' my-8 mx-0 md:mr-4 aspect-square w-60 h-auto profile object-cover object-center rounded-full'
							src={AboutPic}
							alt='Adele in person'></img>
						<p
							className=' w-full text-left md:pl-16 md:pt-8 whitespace-pre-wrap select-none'
							unselectable='true'
							dangerouslySetInnerHTML={{__html: aboutText}}></p>
					</div>
				</div>
			</div>
			<DividerWave direction='up' className='fill-bg_dark' />
		</section>
	)
}

export default About
