import React from 'react'

import {ReactComponent as SecretaryStandbyDarkDress} from './doodles/SecretaryStandby_DarkDress.svg'
import {ReactComponent as SecretaryStandbyLineDress} from './doodles/SecretaryStandby_LineDress.svg'
import {ReactComponent as TakingNotesDarkDress} from './doodles/TakingNotes_DarkDress.svg'
import {ReactComponent as TakingNotesLineDress} from './doodles/TakingNotes_LineDress.svg'
import {ReactComponent as SendNoteDarkDress} from './doodles/SendNote_DarkDress.svg'
import {ReactComponent as SendNoteLineDress} from './doodles/SendNote_LineDress.svg'
// for (let x in Caricatures) {
// 	console.log(x)
// }
// eslint-disable-next-line
function importAll(r, asArray = true) {
	// let images = {}
	let imagesArray = []

	r.keys().forEach((item) => {
		// images[item.replace('./', '')] = r(item)
		imagesArray.push(r(item))
	})
	// console.log('importAll', images, imagesArray)
	// if (asArray) return imagesArray
	// else return images
	return imagesArray
}

const simpleGalleryImages = importAll(require.context('./caricatures/simple', false, /\.(png|jpe?g|svg)$/))
const standardGalleryImages = importAll(require.context('./caricatures/standerd', false, /\.(png|jpe?g|svg)$/))
const premiumGalleryImages = importAll(require.context('./caricatures/premium', false, /\.(png|jpe?g|svg)$/))
const customGalleryImages = importAll(require.context('./caricatures/custom', false, /\.(png|jpe?g|svg)$/))

const caricatures = [...simpleGalleryImages, ...standardGalleryImages, ...premiumGalleryImages, ...customGalleryImages]
export {caricatures, simpleGalleryImages, standardGalleryImages, premiumGalleryImages, customGalleryImages}

export const getRandomCaricature = (amount = 1) => {
	const savedCaris = []
	const data = []
	const maxTry = caricatures.length
	for (let i = 1, m = 1; i <= amount && m <= maxTry; i++, m++) {
		const randomNumber = Math.floor(Math.random() * (maxTry - 1) + 1)

		if (m === maxTry) console.log('m = maxTry reached in getRandomCaricature')
		if (savedCaris.includes(randomNumber)) i--
		else {
			data.push(caricatures[randomNumber])
			savedCaris.push(randomNumber)
		}
	}

	return data
}

export {ReactComponent as AKLogo} from './icons/ak.logo.svg'
export {ReactComponent as Logo} from './icons/AdelezStudio.svg'
export {ReactComponent as AdelezStudio2} from './icons/AdelezStudio.logo.svg'
export {ReactComponent as Lightbulb} from './icons/lightbulb.svg'
export {ReactComponent as FiverrIcon} from './svgs/fiverr-icon.svg'

export {default as AboutPic} from './about/AdeleWithBlur.png'
export {ReactComponent as AKCariSitting} from './about/AKCariSitting.svg'
export {ReactComponent as DoodleOnText} from './about/DoodleOnText.svg'

export {ReactComponent as CaricaturesText} from './svgs/caricaturesText.svg'
// export {default as noise} from './bg/noise.svg'

export {ReactComponent as CopyCop} from './doodles/CopyCop.svg'
export {ReactComponent as LadyFan} from './doodles/LadyFan.svg'
export {ReactComponent as Wakeup} from './doodles/Wakeup.svg'
export {ReactComponent as Slowdown} from './doodles/Slowdown.svg'
export {ReactComponent as Sparkly} from './doodles/Sparkly.svg'
// export {ReactComponent as FrontSitting} from './doodles/FrontSitting.svg'

// export {ReactComponent as PriceBubble} from './svgs/usdBubble.svg'
export {ReactComponent as FingerClickSwipe} from './svgs/fingerClickSwipe.svg'
export {ReactComponent as Lamp} from './svgs/lamp.svg'

// export {default as SpecialShapes} from './svgs/specialShapes.svg'
export {default as TriangleBG} from './svgs/triangleBG.svg'
export {default as BGShapes} from './svgs/BGShapes.svg'
export {ReactComponent as LoadingSVG} from './svgs/LoadingSVG.svg'
// export {default as HandSVG} from './svgs/fingerClickSwipe.svg'

//pros section
export {ReactComponent as Profile} from './pros/profile.svg'
export {ReactComponent as Personalized} from './pros/personalized.svg'
export {ReactComponent as Memory} from './pros/memory.svg'
export {ReactComponent as Gift} from './pros/gift.svg'
export {ReactComponent as AKCariStanding} from './pros/AKCariStanding.svg'

const lineOrDark = Math.random() * 2 > 1
console.log('using random secretary dress style true=lines, false=dark : ', lineOrDark)

export const SendNote = () => (lineOrDark ? <SendNoteLineDress /> : <SendNoteDarkDress />)
export const SecretaryStandby = () => (lineOrDark ? <SecretaryStandbyLineDress /> : <SecretaryStandbyDarkDress />)
export const TakingNotes = () => (lineOrDark ? <TakingNotesLineDress /> : <TakingNotesDarkDress />)
// export default {SendNote, SecretaryStandby, TakingNotes}
