import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'
// import QuoteSelection from './QuoteSelection.js'
import FileUpload from './FileUpload.js'
import PeopleCounter from './PeopleCounter.js'
import WaitSelection from './WaitSelection.js'
import TotalPrice from './TotalPrice.js'
import OrderSelectionSection from './OrderSelectionSection.js'

import './orderPage.css'
import ScrollToTop from '../../utils/scrollToTop.js'

// import {simpleGalleryImages, standardGalleryImages, premiumGalleryImages, customGalleryImages} from '../../img'
// import {shuffleArray} from '../../utils'
import { OrderSummery } from './OrderSummery.js'

import { useFormik } from 'formik'

import * as Yup from 'yup'
import { useLocation } from 'react-use'
import { OrderOptions, defaultOrder, premiumOrder, standardOrder } from './OrderOptions.js'
import { useNavigate } from 'react-router-dom'
import DataService from '../../services/DataService.js'
import { useNotification } from '../../context/NotificationContext'

// Define the validation schema
const validationSchema = Yup.object().shape({
	email: Yup.string().email('Invalid email address').required('Email is required'),
})

const LOCAL_STORAGE_ORDER_KEY = 'orderData'
export const OrderContext = createContext()

function getOrderDataFromLocalStorage() {
	const data = localStorage.getItem(LOCAL_STORAGE_ORDER_KEY)
	return data ? JSON.parse(data) : null
}
function saveOrderDataToLocalStorage(data) {
	localStorage.setItem(LOCAL_STORAGE_ORDER_KEY, JSON.stringify(data))
}
const calculateTotal = (currentOrder) => {
	const choices =
		Number(currentOrder?.style.price | 0) +
		Number(currentOrder?.detail.price | 0) +
		Number(currentOrder?.background.price | 0) +
		Number(currentOrder?.quote.price | 0)
	const choicesTimesPeople = Number(choices) * Number(currentOrder?.people.amount | 0)
	const total = Number(choicesTimesPeople) + Number(currentOrder?.wait.price | 0)
	// console.log('total price:', total)
	if (total < 0) return 0
	return total
}
const calcPriceDiff = (itemPrice, selectedPrice, minPrice) => {
	const prevPrice = selectedPrice || minPrice
	const priceDiff = itemPrice - prevPrice
	const symbol = priceDiff > 0 ? '+' : '-'
	return { priceDiff: Math.abs(priceDiff), symbol }
}
const submitOrder = (values, images, setOrderComplete) => {
	console.log('checkout btn pressed')
	console.log(values)

	// Prepare the data to be sent
	const formData = new FormData()
	// formData.append('currentOrder', JSON.stringify(currentOrder))
	// Append the form fields from Formik to the FormData
	Object.keys(values).forEach((key) => {
		formData.append(key, values[key])
	})
	// Append the selected files to the form data
	for (let i = 0; i < images.length; i++) {
		formData.append('images', images[i])
	}

	// Make a POST request to the backend
	DataService.order(formData)
		// .then((response) => response.json())
		.then((data) => {
			// Process the response from the backend
			console.log('Response from backend:', data)
			setOrderComplete(true)
		})
		.catch((error) => {
			console.error('Error sending data to backend:', error)
		})
}
const EmailInput = ({ header }) => {
	const { currentOrder, setCurrentOrder, formik } = useContext(OrderContext)

	useEffect(() => {
		setCurrentOrder({ ...currentOrder, email: formik.values.email })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [formik.values.email])

	return (
		<div className='flex flex-col pt-4 form_field'>
			<label htmlFor='email' className='mt-8'>
				{header}
			</label>
			{/* <Field type='email' id='email' name='email' />
			<ErrorMessage name='email' component='div' /> */}
			<input
				className={`text-window ${formik.touched.email ? (formik.errors.email ? 'invalid' : 'valid') : ''}`}
				type='email'
				id='email'
				name='email'
				onBlur={formik.handleBlur}
				value={formik.values.email}
				onChange={formik.handleChange}
			/>
			<div className='font-regular ml-4 text-red self-start mt-1 transition-all'>
				{formik.touched.email && formik.errors.email ? formik.errors.email : ''}
			</div>
		</div>
	)
}
const AdditionalDetails = ({ header }) => {
	const { currentOrder, setCurrentOrder, formik } = useContext(OrderContext)
	const [extraDetails, setExtraDetails] = useState(currentOrder.extra)

	useEffect(() => {
		setCurrentOrder({ ...currentOrder, extra: extraDetails })
		formik.setFieldValue('details', extraDetails)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [extraDetails])

	return (
		<div className='flex flex-col pt-4'>
			<label htmlFor='details' className='mt-8'>
				{header}
			</label>
			<textarea className='text-window' id='details' name='details' rows={5} value={extraDetails} onChange={(e) => setExtraDetails(e.target.value)} />
		</div>
	)
}
const UnderConstruction = ({ children }) => {
	const [isVisible, setIsVisible] = useState(false)

	const handleClick = () => {
		setIsVisible(!isVisible)
	}

	return (
		<div>
			{isVisible ? (
				{ ...children }
			) : (
				<div className='flex flex-col'>
					<p className='p-4 m-4 text-center justify-center'>👷‍♂️ This section is under construction</p>
					<br />
					<button className='p-4 m-4 text-center justify-center rounded bg-blue-400 hover:outline-none hover:bg-blue-500' onClick={handleClick}>
						I want to see what is cooking 😋
					</button>
				</div>
			)}
		</div>
	)
}

export const OrderPage = () => {
	const location = useLocation()
	const [userSelectedTierOrder, setUserSelectedTierOrder] = useState(defaultOrder)
	const [orderComplete, setOrderComplete] = useState(false)
	const [images, setImages] = useState([])

	const tierName = location.hash.slice(1)
	const navigate = useNavigate()
	const { setToast } = useNotification()

	useEffect(() => {
		switch (tierName.toLowerCase()) {
			case 'simple':
				setToast(`Auto selected ${tierName} tier based on your selection`)
				setUserSelectedTierOrder(defaultOrder)
				break
			case 'standard':
				setToast(`Auto selected ${tierName} tier based on your selection`)
				setUserSelectedTierOrder(standardOrder)
				break
			case 'premium':
				setToast(`Auto selected ${tierName} tier based on your selection`)
				setUserSelectedTierOrder(premiumOrder)
				break
			default:
				break
		}

		setCurrentOrder(userSelectedTierOrder)
		const currentPath = window.location.pathname
		navigate(currentPath, { replace: true, preventScrollReset: true })
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [tierName])

	const savedOrder = getOrderDataFromLocalStorage()
	if (!savedOrder) saveOrderDataToLocalStorage(userSelectedTierOrder)
	const [currentOrder, _setCurrentOrder] = useState(savedOrder)

	const setCurrentOrder = (newSelection) => {
		const total = calculateTotal(newSelection)
		newSelection = { ...newSelection, total: total }

		saveOrderDataToLocalStorage(newSelection)
		_setCurrentOrder(newSelection)
	}

	// Use useFormik hook to create a form instance
	const formik = useFormik({
		initialValues: { ...currentOrder },
		validationSchema: validationSchema,
		onSubmit: (values) => {
			console.log(values)
			submitOrder(values, images, setOrderComplete)
		},
	})

	const itemsList = useMemo(() => OrderOptions, [])

	return (
		<div id='order' className='font-adelez flex-to-center py-[30vh] min-h-[100vh] max-w-5xl flex-col px-2'>
			<ScrollToTop />
			<UnderConstruction
				children={
					<OrderContext.Provider value={{ currentOrder, setCurrentOrder, itemsList, formik, calcPriceDiff, setOrderComplete, images, setImages }}>
						{orderComplete ? (
							<>Order Complete</>
						) : (
							<>
								<h1 className='text-3xl font-adelez text-orange'>Caricature Order</h1>
								{tierName && (
									<p className='flex text-center m-2'>
										You selected the {tierName} tier.
										<br />
										(Work in progress to autofill the info)
									</p>
								)}
								{/* <h2 className='justify-center items-center'>Work in Progress</h2> */}
								<button
									type='reset'
									className='bg-blue-400 p-2 ml-auto mr-4 cursor-pointer hover:bg-blue-500 rounded-md hover:outline-none'
									onClick={() => setCurrentOrder(userSelectedTierOrder)}>
									Reset
								</button>
								<form onSubmit={formik.handleSubmit}>
									<div className='flex flex-col justify-start'>
										<OrderSelectionSection category='style' header={'Select a Style'} />
										<OrderSelectionSection category='detail' header={'Select level of Detail'} />
										<OrderSelectionSection category='background' header={'Select a Background Type'} />
										{/* <QuoteSelection header={'Your Quote:'} /> */}
										<PeopleCounter header={'Number of People:'} />
										<WaitSelection header={'Wait Time:'} />
										<FileUpload header={'Upload your pictures (at least 2):'} />
										<EmailInput header={'Email:'} />
										<AdditionalDetails header={'Additional Details:'} />
										<TotalPrice />
										<OrderSummery />
									</div>
								</form>
							</>
						)}
					</OrderContext.Provider>
				}
			/>
		</div>
	)
}

// export default OrderPage
