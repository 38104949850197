import {useQuery, useQueryClient} from '@tanstack/react-query'
import {apiBaseUrl} from '../../utils/config'
import {useEffect, useState} from 'react'

const Image = ({src, ...rest}) => <img src={src} alt='Caricature' className='h-auto max-h-[80vh] w-auto shadow-lg m-auto' {...rest} />
const tenDays = 10 * 24 * 60 * 60 * 1000

const fetchImageUrl = async (id) => {
	// const imageUrl = `${googleDriveBaseUrl}${id}`
	const response = await fetch(`${apiBaseUrl}/images/${id}`)
	const json = await response.json()
	return json
}

const fetchImage = async (imageUrl) => {
	const response = await fetch(imageUrl)
	const blob = await response.blob()
	return URL.createObjectURL(blob)
}

export const ImageComponent = ({id, ...rest}) => {
	const [imageUrl, setImageUrl] = useState(null)

	const {
		data,
		isLoading: linkLoading,
		isError: linkError,
	} = useQuery({
		queryKey: ['image', id],
		queryFn: () => fetchImageUrl(id),
		staleTime: tenDays,
		cacheTime: tenDays,
		// onSuccess: (json) => setImageUrl(json.url),
	})
	useEffect(() => {
		if (!linkLoading && !linkError) setImageUrl(data.url)
	}, [data, linkLoading, linkError])

	const {
		data: imageData,
		isLoading: imageLoading,
		isError: imageError,
	} = useQuery({
		queryKey: ['imageData', imageUrl],
		queryFn: () => fetchImage(imageUrl),
		enabled: !!imageUrl,

		staleTime: tenDays,
		cacheTime: tenDays,
	})

	if (imageLoading) return <div>Loading...</div>
	else if (imageError) return <div>Error fetching image.</div>
	else if (!imageLoading && !imageError && imageData) return <Image src={imageData} {...rest} />
}
