// import {Link} from 'react-router-dom'
import {useUser} from '../../context/UserContext'
// import Login from '../Auth/Login'
import {MyLink} from './MyLink'

export const NotFound = ({redirect}) => {
	const user = useUser()
	// if (user.role === 'guest')
	// 	return (
	// 		<>
	// 			<Login />
	// 		</>
	// 	)
	return (
		<div className='flex-to-center flex-col h-[100vh]'>
			<h2 className='font-adelez flex-to-center justify-center items-center'>Page not found</h2>

			<p className='text-left pt-8 whitespace-pre-wrap select-none' unselectable='true'>
				<MyLink to='/profile' text={user.role === 'guest' ? 'sign in' : 'Go to'} />

				{user.role === 'guest' ? 'if you have an account...' : 'your Profile.'}
			</p>

			<p className='text-left pt-8 whitespace-pre-wrap select-none' unselectable='true'>
				Or go to the
				<MyLink to='/' text='Home' />
				page.
			</p>
		</div>
	)
}
