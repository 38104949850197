import React from 'react'
// import './divider_wave.css'
// https://omatsuri.app/page-dividers

const DividerWave = ({direction = 'up', className = ''}) => {
	// const angle = direction === 'up' ? 'up' : 'down'
	return (
		// fill-bg_color
		<div className={className + ' divider p-0 m-0 relative w-full max-h-[100px]'}>
			{/* h-divider */}
			<div className={`wave left-0 right-0 overflow-hidden ${direction === 'up' ? 'rotate-180' : ''}`}>
				{/* changing the width here would make the waves longer  */}
				<svg className='fill-inherit w-full h-divider' preserveAspectRatio='none' viewBox='0 0 1200 120' xmlns='http://www.w3.org/2000/svg'>
					<path
						d='M0 0v46c48 22 104 32 158 28 70-5 136-33 207-37 74-5 147 17 218 35 69 18 138 25 209 13 37-6 70-18 105-29 92-31 216-70 303-4V0z'
						opacity='.25'
					/>
					<path
						d='M0 0v16c13 21 28 41 48 56 51 39 117 39 177 20 31-11 60-26 89-40 41-19 85-46 131-50 36-3 71 10 99 32 31 25 62 62 103 73 41 10 82-7 119-25s76-39 117-43c60-6 114 23 169 39 30 9 59 6 87-7 23-11 48-27 61-50V0z'
						opacity='.5'
					/>
					<path d='M0 0v6c150 53 314 65 476 37 43-8 84-21 127-27 59-9 113 12 166 36 59 25 117 43 182 38 87-7 173-46 249-85V0z' />
				</svg>
			</div>
		</div>
	)
}

export default DividerWave
