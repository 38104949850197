import {useLogout, useUser} from '../../context/UserContext'

export const UserProfile = () => {
	const logout = useLogout()
	const user = useUser()

	return (
		<div className='flex flex-col items-center justify-center h-screen'>
			<h1 className='text-2xl font-bold mb-4'>Profile</h1>
			<div className='bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4'>
				<div className='mb-4'>
					<label className='block text-gray-700 font-bold mb-2'>Email:</label>
					<p className='text-gray-700'>{user.details.email}</p>
				</div>
				<div className='mb-4'>
					<label className='block text-gray-700 font-bold mb-2'>Role:</label>
					<p className='text-gray-700'>{user.role}</p>
				</div>
			</div>
			<button className='bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded' onClick={logout}>
				Logout
			</button>
		</div>
	)
}
