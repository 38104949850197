import React from 'react'
import { Profile, Personalized, Memory, Gift, AKCariStanding } from '../../../img'

// import DividerWave from '../../components/divider_wave/divider_wave'
import { useWhyDidYouUpdate } from '../../../utils/useWhyDidYouUpdate'

// const ProSection = ({Image, header, text}) => {
// 	return (
// 		<div className='flex items-center '>
// 			<Image className='w-20 h-20 m-4 hover:skew-x-2' />
// 			<p className='flex flex-col flex-grow'>
// 				<span className='font-bold'>{header}</span>
// 				<span>{text}</span>
// 			</p>
// 		</div>
// 	)
// }
const Pros = (props) => {
	const { id, forwardRef } = props
	useWhyDidYouUpdate('Specials', props)
	const pros = [
		{
			image: Profile,
			header: 'Unforgettable Gift -',
			text: 'Surprise your loved ones with a personal cartoon character. An original, unique, and memorable gift.',
		},
		{
			image: Personalized,
			header: 'Fully Customizable -',
			text: 'Choose your style, pose, clothes, and background. Get your perfect cartoon portrait.',
		},
		{
			image: Memory,
			header: 'Extraordinary Memory -',
			text: 'Preserve that special family memory in an unforgettable way.',
		},
		{
			image: Gift,
			header: 'Update Social -',
			text: 'Update your social media accounts.',
		},
	]

	return (
		<section id={id} data-section={id} ref={forwardRef} className='relative pt-divider bg-main flex flex-col align-center items-center justify-center'>
			<div className='max-w-3xl relative '>
				<p className='px-20 text-left font-adelez text-3xl sm:text-5xl'>
					<span className='leading-normal'>
						{"Get an "}
						<br className='md:hidden' />
						{"original"}
					</span>
					<br />
					<span className='text-orange leading-normal'>
						{"caricature "}
						<br className='md:hidden' />
						{"design."}
					</span>
				</p>
				<div className='relative'>
					<table className='w-full table-auto'>
						<tbody>
							{pros.map((pro, i) => (
								<tr key={i} className='flex items-center my-10 mx-4'>
									<td className='w-1/5 max-w-[100px]'>
										<pro.image className='w-full h-full object-cover' alt='' />
									</td>
									<td className='w-4/5 sm:w-3/5 mx-6'>
										<span className='font-bold'>{pro.header}</span>
										<p className='text-gray-700'>{pro.text}</p>
									</td>
								</tr>
							))}
						</tbody>
					</table>

					<AKCariStanding className={`relative sm:sticky bottom-2 right-2 w-32 1h-40 pr-6 pb-6 ml-auto`} />
				</div>
			</div>
		</section>
	)
}

export default Pros
