// eslint-disable-next-line
import React, {createContext, useContext} from 'react'
import {Helmet, HelmetProvider} from 'react-helmet-async'
// eslint-disable-next-line no-unused-vars
import {Navigate, Route, Routes} from 'react-router-dom'
// import {Navigate, Route, Routes} from 'react-router-dom'
// import {useUser} from './context/UserContext'
// import {Helmet, HelmetProvider} from 'react-helmet-async'

// import logo from '../build/images/logo_wh.svg'
// eslint-disable-next-line
import {
	// Hero, About, Gallery, Contact, Testimonials, Specials, Tiers, FAQ,
	Footer,
} from './containers'
import Login from './containers/Auth/Login'
import {useUser} from './context/UserContext'

// import Offline from './components/ServicePages/Offline'

function deleteSimilarPaths(routes, userRole) {
	const map = {}
	routes.forEach((route) => {
		const key = route.path
		map[key] = map[key] || 1
		if (route.accessList.includes(userRole)) map[key]++
	})

	routes = routes.filter((route) => map[route.path] <= 1 || route.accessList.includes(userRole))

	return routes
}

const NavigationContext = createContext()

const RouteResolver = ({routes, defaults, Nav}) => {
	const user = useUser()
	const userRole = user.role

	// if (routes.find((route) => route.path === '/')) {
	// 	throw new Error('Path "/" is reserved')
	// }

	routes = deleteSimilarPaths([...routes], userRole)

	return (
		<>
			<HelmetProvider>
				<NavigationContext.Provider value={routes.filter((route) => route.accessList.includes(userRole) && route.isNavigable)}>
					<Routes>
						{/* <Route exact path='/' element={defaults.home} /> */}
						{/* // {<Navigate replace to={defaults.home} />} /> */}
						{/* <Route exact path='/' element={<Navigate replace to={defaults[userRole]} />} /> */}
						{routes.map((route) => {
							const content = route.accessList.includes(userRole) ? (
								<>
									<Helmet>
										<title>{route.title}</title>
									</Helmet>
									<route.component />
								</>
							) : (
								<>
									<Helmet>
										<title>Login to see this Page</title>
									</Helmet>
									<Login />
								</>
							)
							// <Navigate to={defaults[userRole]} />

							return (
								<Route
									key={route.path}
									exact
									// exact={defaults.home === route.path}
									path={route.path}
									element={
										<>
											{/* <div className='page'> */}
											{route.showNavbar && <Nav />}
											<>
												<div className='page-content'>{content}</div>
												{/* {content} */}
												{/* <route.component /> */}
											</>
											{route.showFooter && <Footer />}
											{/* </div> */}
										</>
									}
								/>
							)
						})}
					</Routes>
				</NavigationContext.Provider>
			</HelmetProvider>
		</>
	)
}

const useNavigation = () => {
	const context = useContext(NavigationContext)
	if (context === undefined) {
		throw new Error('useNavigation must be used inside component passed to RouteResolver')
	}
	return context
}

export {useNavigation, RouteResolver}
