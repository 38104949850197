// eslint-disable-next-line no-unused-vars
import React, {useEffect, useState} from 'react'
import {Helmet} from 'react-helmet-async'
import {useParams} from 'react-router-dom'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {apiBaseUrl} from '../../utils/config'
import {ImageComponent, fetchImageUrl} from '../Common/ImageComponent'
// import axios from 'axios'
// import Loading from '../Loading'
// import BlobImage from '../../components/blobImage/blobImage'
// import {googleDriveBaseUrl} from '../../utils/useImageLoader'
function CopyLinkButton({link}) {
	const [isCopied, setIsCopied] = useState(false)

	const handleClick = () => {
		navigator.clipboard.writeText(link)
		setIsCopied(true)
		setTimeout(() => setIsCopied(false), 2000)
	}

	return (
		<div className='flex flex-col items-center'>
			<button
				className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline'
				onClick={handleClick}>
				{isCopied ? 'Link copied!' : 'Copy link'}
			</button>
		</div>
	)
}
const CaricaturePage = () => {
	const {id} = useParams()

	const currentPageLink = window.location.href

	// const queryClient = useQueryClient()

	// const cachedData = queryClient.getQueryData(['image', id])

	return (
		<div className='flex flex-col items-center justify-center min-h-screen py-navHeight'>
			<Helmet>
				{/* {cachedData && <meta property='og:image' content={cachedData.thumbnail} />} <meta property='og:title' content='Caricature Page' /> */}
				<meta property='og:description' content='Check out this awesome caricature!' />
			</Helmet>
			{/* {isLoading && <p>Loading image...</p>} */}
			{/* {isError && <p>Image not found.</p>} */}
			{/* {!isLoading && isError && */}
			{/* <div className='flex justify-center text-center w-full h-full'> */}
			<div className=' max-w-xl w-4/5 relative flex justify-center text-center'>
				{/* <Image src={imageUrl} /> */}
				<ImageComponent id={id} />
				{/* {isLoading && <BlobImage text={'Loading Image...'} />}
				{isError && <BlobImage text={'an error occured while loading'} src={imageUrl} />}
				{imageData && <Image src={imageData} />} */}
			</div>
			{/* </div> */}
			{/* } */}
			<p className='mt-4 text-lg font-bold'>Check out this Caricature!</p>
			<div className='text-gray-500 flex items-center pt-4'>
				<span className='pr-2 text-lg'>Share this page:</span>
				<CopyLinkButton link={currentPageLink} />
			</div>
		</div>
	)
}

export default CaricaturePage
