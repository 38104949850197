import {useEffect, useRef, useState} from 'react'

export const ImageFileSelector = ({setFile, file = null, maxSize = 3 * 1024 * 1024}) => {
	const [error, setError] = useState(null)
	const [image, setImage] = useState(file)
	const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg']

	const maxFileText = `Max file size: ${maxSize / (1024 * 1024)}MB`

	useEffect(() => {
		setFile(image)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [image])

	const handleFileChange = (event) => {
		const selectedFile = event.target?.files[0]

		if (selectedFile) {
			if (!allowedTypes.includes(selectedFile.type)) {
				setImage(null)
				setError('Only png & jp[e]g files Allowed!')
			} else if (selectedFile.size > maxSize) {
				setImage(null)
				setError('Check the file size, send on TG to shrink...')
			} else {
				setImage(selectedFile)
				setError(null)
			}
		} else {
			setImage(null)
			setError('Select a file.')
		}
	}
	const fileInputRef = useRef(null)

	const handleDivClick = () => {
		fileInputRef.current.click()
	}

	return (
		<div className='relative w-full h-40 border-dashed border-2 border-gray-400 rounded-lg transition-all'>
			<div className={`flex flex-col justify-center items-center h-full cursor-pointer ${image ? 'hidden' : ''}`} onClick={handleDivClick}>
				<p className='text-gray-600 font-bold mb-2'>Click to select a picture</p>
				<label htmlFor='file' className='text-blue-500 font-semibold cursor-pointer'>
					Browse files
				</label>
				<input type='file' id='file' ref={fileInputRef} className='hidden' accept={allowedTypes} onChange={handleFileChange} required />
				<p className='text-gray-400 text-xs mt-2'>{maxFileText}</p>
				{error && <p className='text-red text-xs mt-2'>{error}</p>}
			</div>
			<div className={`w-full h-full cursor-pointer ${!image ? 'hidden' : ''}`} onClick={handleDivClick}>
				{image && (
					<img
						className='max-w-full h-full rounded-lg object-contain'
						src={URL.createObjectURL(image)}
						alt='Preview'
						// onClick={() => fileInputRef.current.click()}
					/>
				)}
			</div>
		</div>
	)
}
