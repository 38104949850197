import {useEffect, useState} from 'react'
import {useMutation} from '@tanstack/react-query'
import DataService from '../../../services/DataService'
import {FaTimes} from 'react-icons/fa'

function UploadButton({tags, file, onUpload}) {
	const [uploadProgress, setUploadProgress] = useState(0)
	const [isUploading, setIsUploading] = useState(false)

	const formData = new FormData()

	// Append the file to the form data
	formData.append('file', file)

	// Append other form fields to the form data
	formData.append('tags', tags)

	// const imageUploader = useMutation((formData) => DataService.uploadImage(formData))
	const imageUploader = useMutation(() => DataService.uploadImage(formData), {
		onUploadProgress: (progressEvent) => {
			const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
			setUploadProgress(progress)
		},
	})
	const {error} = imageUploader

	const cancelUpload = () => {
		imageUploader.reset()
		setIsUploading(false)
	}

	useEffect(() => {
		file && console.log('file is', file.name)
	}, [file])

	const handleClick = async () => {
		setIsUploading(true)

		try {
			const response = await imageUploader.mutateAsync()
			onUpload(response)
			setIsUploading(false)
		} catch (error) {
			console.error(error)
			setIsUploading(false)
		}
	}

	return (
		<div className='flex flex-col'>
			<button
				type='button'
				className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${isUploading ? 'hidden' : ''}`}
				onClick={handleClick}>
				Upload
			</button>
			<div className={`relative rounded overflow-hidden ${isUploading ? '' : 'hidden'}`}>
				<div className='bg-blue-500 h-2 absolute top-0 left-0' style={{width: `${uploadProgress}%`}}></div>
				<button type='button' className='bg-red-500 text-white absolute top-0 right-0 px-2 py-1' onClick={cancelUpload}>
					<FaTimes />
				</button>
				<button type='button' className='bg-blue-500 text-white font-bold py-2 px-4 rounded opacity-50 cursor-not-allowed' disabled>
					Uploading...
				</button>
			</div>

			{error && <div className='bg-red-500 text-red p-2 mb-4'>{error}</div>}
		</div>
	)
}
export default UploadButton
