// import React, {useState} from 'react'
import {useQuery, useQueryClient} from '@tanstack/react-query'
import {useLogout} from '../../../context/UserContext'
import DataService from '../../../services/DataService'
import {useEffect, useState} from 'react'
import {timeSincePrettyString} from '../../../utils/timeUtils'
import {TagsList} from '../../../utils/TagsList'
// import {apiBaseUrl} from '../../utils/config'
import {googleDriveBaseUrl} from '../../../utils/useImageLoader'
import {FaCheck, FaClipboard, FaClipboardCheck, FaTimes, FaTrashAlt} from 'react-icons/fa'
import {ImageComponent} from '../../Common/ImageComponent'
import {useAdminData} from '../../../context/AdminDataContext'
// import {toggleVisibility} from '../api' // import your API function for toggling visibility here
const handleCopyClick = (text) => {
	navigator.clipboard.writeText(text)
}
// const getImageByID = (id) => requestFactory.post(`/images/${id}`)
// const getImageByID = async (gid) => {
// 	try {
// 		const response = await requestFactory.post(`/images/${gid}`)

// 		// Assuming the response contains the image data
// 		return response
// 	} catch (error) {
// 		console.log('Error:', error)
// 		throw error
// 	}
// }

const reqDeleteImage = DataService.delImage

const DeleteButton = ({item, hideItem}) => {
	const [confirmingDelete, setConfirmingDelete] = useState(false)

	const handleDelete = async () => {
		if (confirmingDelete) {
			const deleted = await reqDeleteImage({MongoID: item.id, GID: item.gid})
			if (deleted.ok) {
				console.log('handleDelete', deleted)
				hideItem()
			}
		} else {
			setConfirmingDelete(true)
		}
	}

	const handleCancel = () => {
		setConfirmingDelete(false)
	}

	return (
		<div className='flex flex-col justify-center items-center transition-all'>
			{confirmingDelete ? (
				<>
					<p className=''>Are you sure you want to delete this item?</p>
					<div className='flex'>
						<button onClick={handleDelete} className='m-4 p-2 bg-transparent rounded'>
							<FaCheck />
						</button>
						<button onClick={handleCancel} className='m-4 p-2 bg-transparent rounded'>
							<FaTimes />
						</button>
					</div>
				</>
			) : (
				<button onClick={handleDelete} className='m-4 p-2 bg-transparent rounded'>
					<FaTrashAlt />
				</button>
			)}
		</div>
	)
}

const TableRow = ({item}) => {
	const [deleted, setDeleted] = useState(false)
	const timestamp = Date.parse(item.createdAt)
	// const shortenedGid = `${item.gid.substring(0, 7)}...${item.gid.substring(item.gid.length - 7)}`
	// const imageUrl = `${apiBaseUrl}/images/${item.gid}`
	// const imageUrl = `${googleDriveBaseUrl}${item.gid}`
	// const imageUrl = `${item.thumbnail}`
	// const {isLoading, data: img, isError} = useImageLoader(imageUrl, item.gid)
	// console.log(isLoading, img, isError)
	const [isCopied, setIsCopied] = useState(false)

	const handleClick = () => {
		handleCopyClick(item.gid)
		setIsCopied(true)
		setTimeout(() => setIsCopied(false), 3000)
	}

	return (
		<tr key={item.id} className={`relative${deleted && ' hidden'}`}>
			<td className='border md:px-4 md:py-2 cursor-copy relative' onClick={handleClick}>
				<input value={item.id} disabled />
				{/* <div className='hidden md:block lg:hidden'>{shortenedGid}</div>
				<div className='hidden lg:block'>{item.gid}</div> */}
				<div className='mt-auto p-2 flex items-center justify-center h-auto'>{isCopied ? <FaClipboardCheck /> : <FaClipboard />}</div>
			</td>
			<td className='border px-4'>
				<TagsList tags={item.tags} />
			</td>
			<td className='border w-40 cursor-pointer'>
				{/* <ImageComponent gid={item.gid} alt={item.category} onClick={() => window.open(`/images/${item.gid}`, '_blank')} /> */}
				{/* <img src={async () => await getImageByID(item.gid)} alt={item.category} onClick={() => window.open(`/images/${item.gid}`, '_blank')} /> */}
				{/* {isLoading ? (
					'loading image'
				) : isError ? (
					<>
						error loading image*/}
				<ImageComponent id={item.id} alt={item.category} onClick={() => window.open(`/images/${item.id}`, '_blank')} />

				{/* <img
					src={imageUrl}
					// src={async () => await requestFactory.get(`/images/${item.gid}`)}
					alt={item.category}
					onClick={() => window.open(`/images/${item.id}`, '_blank')}
				/> */}
				{/*</>
				) : ( 
				<img
					src={img}
					// src={async () => await requestFactory.get(`/images/${item.gid}`)}
					alt={item.category}
					onClick={() => window.open(`/images/${item.gid}`, '_blank')}
				/>
				 )} */}
			</td>
			<td className='border px-4 py-2'>{timeSincePrettyString(timestamp)}</td>
			<td className='border md:px-4 md:py-2'>
				<DeleteButton item={item} hideItem={() => setDeleted(true)} />
			</td>
		</tr>
	)
}

// createdAt: '2023-06-02T17:33:05.074Z'
// filePath: '/custom.2_5gjipXg-y.jpeg'
// gid: '1ct_80PL95ePfISGh_G09WVl9EnCUyZha'
// height: 1280
// id: '647a27d15371b8a28c616485'
// ikid: '647a27cf06370748f272778e'
// name: 'custom.2_5gjipXg-y.jpeg'
// size: 95805
// tags: []
// thumbnail: 'https://ik.imagekit.io/adelez/tr:n-ik_ml_thumbnail/custom.2_5gjipXg-y.jpeg'
// url: 'https://ik.imagekit.io/adelez/custom.2_5gjipXg-y.jpeg'
// visible: false
// width: 1280

const ImagesDashboard = () => {
	const [errorMsg, setErrorMsg] = useState(null)
	// const toggleVisibility = async ({id, visibility}) => {
	// 	console.log('todo change visbibiliy of id', id)
	// }
	const logout = useLogout()

	// const handleVisibilityToggle = async (id, visibility) => {
	// 	try {
	// 		// const updatedData =
	// 		// await mutateVisibility({id, visibility})
	// 		// Do something with updatedData (e.g. show success message)
	// 	} catch (error) {
	// 		// Handle error (e.g. show error message)
	// 		console.log(error)
	// 	}
	// }
	const [db, isLoading, isError, error] = useAdminData()

	return (
		<div className=' relative flex flex-col items-center justify-center overflow-none'>
			{/* flex-grow */}
			<h1 className='text-3xl font-bold mb-6'>Images in Database/Google Drive</h1>
			{isError ? (
				<p>
					Error: {errorMsg}
					{error?.response?.status === 418 && (
						<>
							<p>
								Access Token is out of date... please
								<button onClick={logout} className='px-2 mx-1 py-1 rounded-md bg-orange bg-opacity-50 hover-underline-animation hover:outline'>
									logout
								</button>{' '}
								and log back in...
							</p>
						</>
					)}
				</p>
			) : isLoading ? (
				<p>Loading...</p>
			) : db && db.length > 0 ? (
				<table className='table-auto border-collapse border border-gray-800 overflow-y-scroll relative'>
					<thead>
						<tr>
							<th className='px-4 py-2 text-white bg-gray-800'>ID</th>
							<th className='px-4 py-2 text-white bg-gray-800'>Tags</th>
							<th className='px-4 py-2 text-white bg-gray-800'>Preview</th>
							<th className='px-4 py-2 text-white bg-gray-800'>Uploaded</th>
							<th className='px-4 py-2 text-white bg-gray-800'>Delete?</th>
						</tr>
					</thead>
					<tbody>
						{db.map((item) => (
							<TableRow key={item.id} item={item} />
						))}
					</tbody>
				</table>
			) : (
				<p>No data saved yet. upload a picture above</p>
			)}
		</div>
	)
}

export default ImagesDashboard
