import React, {useState} from 'react'
import {useSprings, animated, to as interpolate} from '@react-spring/web'
import {useDrag} from '@use-gesture/react'
import ZoomableImage from '../../Common/ZoomableImage'
import './deck.css'
// import {FaArrowCircleLeft, FaArrowCircleRight, FaWindowClose} from 'react-icons/fa'

// These two are just helpers, they curate spring data, values that are later being interpolated into css
const to = (i) => ({
	x: 0,
	y: i * -4,
	scale: 1,
	rot: -10 + Math.random() * 20,
	delay: i * 100,
})
const from = () => ({x: 0, rot: 0, scale: 1.5, y: -1000})
// This is being used down there in the view, it interpolates rotation and scale into a css transform
// const trans = (r, s) => `perspective(1500px) rotateX(30deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`
const trans = (r, s) => `rotateX(0deg) rotateY(${r / 10}deg) rotateZ(${r}deg) scale(${s})`

export const Deck = ({pictures, onDrag, zoomOutShowTip}) => {
	const [gone] = useState(() => new Set()) // The set flags all the pictures that are flicked out

	// const isTap = useRef(false)

	const [props, api] = useSprings(pictures.length, (i) => ({
		...to(i),
		from: from(i),
	})) // Create a bunch of springs using the helpers above
	// Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
	const bind = useDrag(
		// eslint-disable-next-line
		({args: [index], active, movement: [mx, my], direction: [xDir, yDir], velocity: [vx], tap, cancel, event}) => {
			// if (tap && !active) return

			// Ignore vertical gestures
			// if (Math.abs(yDir) > Math.abs(xDir)) {
			// 	cancel()
			// 	// return
			// }
			// Ignore vertical gestures
			// if (Math.abs(yDir) > Math.abs(xDir)) {
			// 	return
			// }

			// Prevent default touch behavior on mobile devices
			// if (event.touches && event.touches.length > 1) {
			// 	event.preventDefault()
			// }

			const trigger = vx > 0.2 // If you flick hard enough it should trigger the card to fly out
			if (!active && trigger) gone.add(index) // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
			api.start((i) => {
				onDrag()

				if (index !== i) return // We're only interested in changing spring-data for the current spring
				const isGone = gone.has(index)
				const x = isGone ? (200 + window.innerWidth) * xDir : active ? mx : 0 // When a card is gone it flys out left or right, otherwise goes back to zero
				const rot = mx / 100 + (isGone ? xDir * 10 * vx : 0) // How much the card tilts, flicking it harder makes it rotate faster
				const scale = active ? 1.1 : 1 // Active pictures lift up a bit
				const opacity = isGone ? 0 : 1 // Set opacity to 0 for flicked images
				const display = isGone ? 'none' : 'block' // Set display to none for flicked images
				return {
					x,
					rot,
					scale,
					opacity,
					display,
					delay: undefined,
					config: {friction: 50, tension: active ? 800 : isGone ? 200 : 500},
				}
			})
			if (!active && gone.size === pictures.length)
				setTimeout(() => {
					gone.clear()
					api.start((i) => to(i))
				}, 600)
		},
		{
			// axis: 'x',
			direction: 'horizontal',
			preventDefault: true,
			filterTaps: true,
			capture: true,
			passive: false,
		}
		// {axis: 'x', preventDefault: true, filterTaps: true, capture: true, passive: false}
	)

	// Now we're just mapping the animated values to our view, that's it. Btw, this component only renders once. :-)
	return (
		// snap-proximity h-[var(--deck-item-height)] md:w-[50vw] - snap-mandatory
		// stack
		// overflow-y-visible relative
		<div className='deck-container flex-to-center'>
			{props.map(({x, y, rot, scale}, i) => (
				<animated.div className='deck-wrapper flex-to-center' key={i} style={{x, y}}>
					<ZoomableImage
						className='gallery-image'
						srcSmall={pictures[i]}
						srcLarge={pictures[i]}
						alt=''
						style={{transform: interpolate([rot, scale], trans)}}
						{...bind(i)}
						draggable={false}
						// onWheel={() => api.cancel()}
						zoomOutShowTip={zoomOutShowTip}
					/>
				</animated.div>
			))}
		</div>
	)
}

// export default Deck
