import axios from 'axios'
import React from 'react'
import {useUser} from '../../context/UserContext'

const MyOrdersPage = () => {
	const user = useUser()
	const {email} = user
	const handleOrderRequest = async () => {
		const token = localStorage.getItem('token')
		try {
			const response = await axios.get(`/api/orders?email=${email}`, {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
			console.log(response.data)
		} catch (error) {
			console.error(error)
		}
	}

	return (
		<div className='flex flex-col items-center justify-center min-h-screen'>
			<h2>{email}'s Orders</h2>
			<p>This page shows the orders for the user with the email {email}.</p>
			<button onClick={handleOrderRequest}>Make Order Request</button>
		</div>
	)
}

export default MyOrdersPage
