import {createContext, useContext, useEffect, useState} from 'react'
import DataService from '../services/DataService'
import {useQuery} from '@tanstack/react-query'
import {useLogout} from './UserContext'
// import DataService from '../services/DataService'

// import {useAsync} from '../utils/useAsync'
// import {useNotification} from './NotificationContext'

const AdminDataContext = createContext()
const LOCAL_STORAGE_DB_KEY = 'db'
const emptyDB = {
	categories: [],
	images: [],
}
const AdminDataProvider = ({children}) => {
	const loadedDB = JSON.parse(localStorage.getItem(LOCAL_STORAGE_DB_KEY)) || emptyDB

	const [db, setDB] = useState(loadedDB)
	const logout = useLogout()
	// const autoDispatch = (db = null) => {
	// 	if (db) {
	// 		localStorage.setItem(LOCAL_STORAGE_DB_KEY, JSON.stringify(db))
	// 		setDB(db)
	// 	} else {
	// 		localStorage.removeItem(LOCAL_STORAGE_DB_KEY)
	// 		setDB(emptyDB)
	// 	}
	// }

	// const {setToast} = useNotification()

	const {isLoading, data, error, isError} = useQuery({
		queryKey: ['dbData'],
		queryFn: DataService.prepDB,
		staleTime: 10 * 60 * 1000, // 10min
	})
	useEffect(() => {
		// asyncFn(DataService.adminGetAll()).then(setDB).catch(console.log).finally(handleLoaded)
		// eslint-disable-next-line
		if (!isError) {
			setDB((oldDB) => ({...oldDB, ...data}))
			localStorage.setItem(LOCAL_STORAGE_DB_KEY, JSON.stringify(db))
		} else if (error?.response && error?.response.status) {
			logout()
			console.log('HTTP status code:', error.response.status)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data, isError, error])

	return <AdminDataContext.Provider value={[db, isLoading, isError, error]}>{children}</AdminDataContext.Provider>
}

const useAdminData = () => {
	const context = useContext(AdminDataContext)
	if (context === undefined) {
		throw new Error('useAdminData must be used within a AdminDataProvider')
	}

	return context
}

export {useAdminData, AdminDataProvider}
