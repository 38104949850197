// import axios from 'axios'
import React from 'react'
// eslint-disable-next-line no-unused-vars
import {apiBaseUrl} from '../../utils/config'
import ImagesDashboard from './Images/ImagesBoard'
import UploadImageForm from './Uploader/UploadImageForm'
import EditCategories from './Categories/EditCategories.js'
import AdminTabs from './AdminTabs'
import {useAdminData} from '../../context/AdminDataContext'

const AdminPage = () => {
	const [db, isLoading, isError, error] = useAdminData()
	return (
		<div className='flex flex-col items-center justify-center min-h-screen'>
			<h2>Admin Page</h2>
			<p>This page is only visible to admins.</p>
			{/* <button onClick={handleAdminRequest}>Make Admin Request</button> */}
			{/* <UploadImageForm /> */}
			<h2>-----</h2>

			<p>
				<span>TODO:</span>
				<br />
				- add delete button (delete from mongo and google drive), it should verify client side if to delete
				<br />- filter option for the table
				<br />- sort options for the table
				<br />- add pagination to load pics not all at once
			</p>
			<h2>-----</h2>

			{/* <ImagesDashboard /> */}
			{!isLoading && !isError ? (
				<AdminTabs
					tabs={[
						{
							name: 'Upload new image',
							component: UploadImageForm,
						},
						{
							name: 'Caricatures in system',
							component: ImagesDashboard,
						},
						{
							name: 'Edit categories',
							component: EditCategories,
						},
						// {
						// 	name: 'Edit Users',
						// 	component: EditUsers,
						// },
					]}
				/>
			) : (
				<>{error}</>
			)}
		</div>
	)
}

export default AdminPage
