// eslint-disable-next-line
import React, { useRef, useState } from 'react'
// import './faq.css'
import DividerWave from '../../../components/divider_wave/divider_wave'
import { TriangleBG } from '../../../img'
// import { scrollIntoView } from '../../../utils/scroll_into_view'
// import {Title} from '../../components'
import { useWhyDidYouUpdate } from '../../../utils/useWhyDidYouUpdate'

import faqsArray from './faqArray'
import { isiOS } from '../../../utils/browserCheck'

const FAQ_section = (props) => {
	const { id, forwardRef } = props
	useWhyDidYouUpdate('FAQ_section', props)

	return (
		<section
			// style={{ backgroundImage: `url(${TriangleBG})` }}
			style={{
				backgroundImage: `${isiOS ? '' : `url(${TriangleBG})`}`,
			}}
			className='relative flex-to-center flex-col z-faq bg-fixed bg-origin-border bg-main bg-left bg-cover bg-no-repeat'
			id={id}
			data-section={id}
			ref={forwardRef}>
			{/* Frequently Asked Questions */}
			<h1 className='title'>FAQ</h1>
			<FaqList />
			<DividerWave direction='up' />
		</section>
	)
}

const FaqList = () => {
	const increment = 3
	const [numVisibleFaqs, setNumVisibleFaqs] = useState(increment)
	const [faqs, setFaqs] = useState(faqsArray)

	const toggleFAQ = (id) => {
		setFaqs(
			faqs.map((faq, i) => {
				return {
					...faq,
					open: i === id ? !faq.open : false,
				};
			})
		);
	}

	const setNumberAndScroll = (num) => {
		setNumVisibleFaqs(num)
		toggleFAQ(num - increment)

		// const openFaq = document.querySelector('.faq.open')
		// console.log(openFaq)
		// const btns = document.getElementById('faqShowMore')

		// const offset = btns.offsetHeight - 300
		// openFaq.scrollTo({behavior: 'smooth'})
		// btns.focus()
		// scrollIntoView(openFaq)
	}
	const handleShowMore = () => setNumberAndScroll(numVisibleFaqs + increment)

	const handleShowLess = () => setNumberAndScroll(numVisibleFaqs - increment)

	const visibleFaqs = faqs.filter((faq) => !faq.hidden).slice(0, numVisibleFaqs)

	return (
		<div className='faqs max-w-3xl'>
			{visibleFaqs.map((faq, index) => (
				<FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
			))}
			<div className='text-center' id='faqShowMore'>
				{/* {numVisibleFaqs < faqs.filter((faq) => !faq.hidden).length && ( */}
				<button
					className='bg-transparent outline-none enabled:hover:underline disabled:opacity-50'
					disabled={numVisibleFaqs >= faqs.filter((faq) => !faq.hidden).length}
					onClick={handleShowMore}>
					Show More
				</button>
				{/* )} {numVisibleFaqs < faqs.filter((faq) => !faq.hidden).length && numVisibleFaqs > increment &&  */}
				<span className='px-6 font-adelez'>|</span>
				{/*} {numVisibleFaqs > increment && ( */}
				<button
					className='bg-transparent outline-none enabled:hover:underline disabled:opacity-50'
					disabled={numVisibleFaqs <= increment}
					onClick={handleShowLess}>
					Show Less
				</button>
				{/* )} */}
			</div>
		</div>
	)
}
const FAQ = ({ faq, index, toggleFAQ }) => {
	return (
		<div className={`faq${faq.open ? ' open shadow-orange' : ''}`} onClick={() => toggleFAQ(index)}>
			<div className={`faq-question relative pr-5${faq.open ? ' font-semibold' : ''}`}>{faq.q}</div>
			<p className='faq-answer' dangerouslySetInnerHTML={{ __html: faq.a }} />
		</div>
	)
}

export default FAQ_section
