// eslint-disable-next-line
import React, {useEffect, useState} from 'react'
// import ReactDOM from 'react-dom/client'

import {FaAngleLeft, FaAngleRight} from 'react-icons/fa'
// import { FaLink } from 'react-icons/fa'
// import axios from 'axios'
// https://www.npmjs.com/package/react-social-media-embed#installation
import {FacebookEmbed, InstagramEmbed, LinkedInEmbed, TwitterEmbed, TikTokEmbed} from 'react-social-media-embed'
import {scrollIntoView} from '../../../utils/scroll_into_view'
// import {FaArrowLeft, FaArrowRight} from 'react-icons/fa'
import FinalTestimony from './finalTestimony'
// import {useSpring, useChain,  from '@react-spring/web'
// import {Fade} from 'react-reveal'

import testimonials from './testimoniesArray'

// eslint-disable-next-line
import {EffectFade, Mousewheel, Keyboard, Autoplay, EffectCards, Thumbs, Navigation, Pagination, Scrollbar, A11y} from 'swiper'
// import {BGShapes} from '../../img'

import {Swiper, SwiperSlide} from 'swiper/react'
// import axios from 'axios'
// import { apiBaseUrl } from '../../../utils/config'
import ZoomableImage from '../../Common/ZoomableImage'
// import {Title} from '../../components'
// TODO https://swiperjs.com/swiper-api#lazy-loading
const Testimonials = ({forwardRef, id}) => {
	const [testimonies, setTestimonies] = useState(testimonials)

	// const [isLoading, setIsLoading] = useState(false)
	// setIsLoading(false)
	// const [isLoaded, setIsLoaded] = useState(true)

	// useEffect(() => {
	// 	const failLoadTestimonies = () => {
	// 		console.log('testimonials', testimonials)
	// 		console.log('testimonies get failed or returned 0 elements')
	// 		setTestimonies(testimonials)
	// 		setIsLoading(false)
	// 		setIsLoaded(false)
	// 		console.log('testimonies', testimonies)
	// 	}
	// 	axios
	// 		.get(`${apiBaseUrl}/reviews`)
	// 		.then(({ data }) => {
	// 			console.log(data)
	// 			if (data?.length > 0) {
	// 				setTestimonies(data)
	// 				setIsLoading(false)
	// 				setIsLoaded(true)
	// 			} else failLoadTestimonies()
	// 		})
	// 		.catch((e) => failLoadTestimonies())
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [])
	return (
		<section
			ref={forwardRef}
			id={id}
			data-section={id}
			// style={{backgroundImage: `url(${BGShapes})`}}
			className='relative pb-8 z-testimony bg-main bg-left bg-cover bg-no-repeat bg-fixed rounded-b-3xl'>
			{/* <div className='border-t-2 border border-orange'></div> */}
			<h1 className='title'>A word from our customers</h1>
			<div className='relative max-w-lg mx-auto px-6 flex-to-center rounded-md lg:max-w-xl bg-offwhite'>
				{/* <div className='testimony-carousel'> */}
				<Swiper
					// install Swiper modules
					id='testimony-carousel'
					modules={[Navigation, Keyboard, Pagination, A11y, Mousewheel, Autoplay]}
					spaceBetween={0}
					slidesPerView={1}
					navigation={{
						nextEl: '.button-next',
						prevEl: '.button-prev',
					}}
					// autoHeight={true}
					// watchSlidesProgress
					// pagination={{
					// 	type: 'fraction',
					// renderFraction: (currentClass, totalClass) => {
					// 	console.log('renderFraction currentClass, totalClass', currentClass, totalClass)
					// },
					// formatFractionCurrent: (num) => {
					// 	return 'Slide ' + num
					// },
					// formatFractionTotal: (num) => {
					// 	return ' of ' + num + ' slides'
					// },
					// clickable: true,
					// }}
					// autoplay={{ delay: 50000 }}
					mousewheel={false}
					keyboard={{
						enabled: true,
						onlyInViewport: true,
					}}
					rewind
					// removeClippedSubviews={false}
					// effect='fade'
					// scrollbar={{draggable: true}}
					// onSlideChangeTransitionEnd={() => {
					// 	scrollIntoView(undefined, 'testimonies')
					// }}
					onClick={() => {
						scrollIntoView(undefined, 'testimonies')
					}}>
					{/* {isLoading ? (
						<SwiperSlide key={'loading'} className='h-auto'>
							{({ isActive }) => (
								<div
									key={'loading'}
									className={`testimony${isActive ? ' active' : ''}`}
									// this fixes the auto height issue after opening an embeded link
									style={{
										...(isActive ? {} : { display: 'none' }),
									}}>
									<p>Loading testimonies...</p>
								</div>
							)}
						</SwiperSlide>
					) : ( */}
					{/* // ) : isLoaded ? (
						// 	'' */}
					{testimonies.map((testimony, i) => {
						// console.log(testimony, i)
						return (
							<SwiperSlide key={i} className='h-auto'>
								{({isActive}) => {
									// console.log(isActive)

									return (
										<div
											key={i}
											className={`testimony${isActive ? ' active' : ''}`}
											// this fixes the auto height issue after opening an embeded link
											style={{
												...(isActive ? {} : {display: 'none'}),
											}}>
											<Testimony name={testimony.name} text={testimony.text} image={testimony.imageUrl} source={testimony.source} />
										</div>
									)
								}}
							</SwiperSlide>
						)
					})}
					{/* )} */}
					<SwiperSlide className='h-auto'>
						{({isActive}) => (
							<div key={'add'} className={`testimony${isActive === '3' ? ' active' : ''}`}>
								<FinalTestimony setTestimonies={setTestimonies} />
							</div>
						)}
					</SwiperSlide>
				</Swiper>
				<button className='button-prev nav-button rounded-l-md left-0'>
					<FaAngleLeft />
				</button>
				<button className='button-next nav-button right-0 rounded-r-md'>
					<FaAngleRight />
				</button>
				{/* <div className='pagination'></div> */}
			</div>
		</section>
	)
}

const Testimony = ({name, text, source, image}) => {
	const [embedData, setEmbedData] = useState(null)
	const [isLoading, setIsLoading] = useState(false)
	// const [isLoaded, setIsLoaded] = useState(false)
	const [error, setError] = useState(null)
	const [sourceReqAndExists, setSourceReqAndExists] = useState(false)

	// eslint-disable-next-line
	const getEmbed = (e) => {
		async function fetchEmbedLink() {
			const element = document.getElementById('testimony-carousel')
			console.log(element.offsetWidth)
			const width = '100%' // element.offsetWidth - 40
			console.log(width)
			try {
				let response
				switch (true) {
					case source.includes('instagram'):
						response = (
							<InstagramEmbed
								placeholderImageUrl={image}
								onLoad={() => setIsLoading(false)}
								onError={() => setError(true)}
								url={source}
								width={width}
								captioned
								onL
							/>
						)
						break
					case source.includes('facebook'):
						response = (
							<FacebookEmbed placeholderImageUrl={image} onLoad={() => setIsLoading(false)} onError={() => setError(true)} url={source} width={width} />
						)
						break
					case source.includes('twitter'): // || source.includes('t.co'): this doesnt work
						response = (
							<TwitterEmbed placeholderImageUrl={image} onLoad={() => setIsLoading(false)} onError={() => setError(true)} url={source} width={width} />
						)
						break
					case source.includes('tiktok'):
						response = (
							<TikTokEmbed placeholderImageUrl={image} onLoad={() => setIsLoading(false)} onError={() => setError(true)} url={source} width={width} />
						)
						break
					case source.includes('linkedin'):
						response = <LinkedInEmbed url={source} postUrl='' width={width} height={500} />
						break
					default:
						setSourceReqAndExists(false)
						setIsLoading(false)
						window.open(source, '_blank', 'noopener noreferrer')
						return
				}
				setEmbedData(response)
				setSourceReqAndExists(true)
				setIsLoading(false)
			} catch (e) {
				setError(e)
				setIsLoading(false)
			}
		}

		if (source) {
			e.preventDefault()
			setIsLoading(true)
			fetchEmbedLink()
		}
	}
	const zoomOutShowTip = () => null

	return (
		<>
			{source && sourceReqAndExists && !error && !isLoading ? (
				<div className='relative mx-auto text-center flex justify-center w-full'>{embedData}</div>
			) : (
				<>
					<div className='py-6 flex justify-center'>
						<ZoomableImage
							className='h-12 w-12 rounded-full cursor-pointer pointer-events-auto'
							srcSmall={image}
							srcLarge={image}
							alt={'Testimony by ' + name}
							zoomOutShowTip={zoomOutShowTip}
						/>
						<h3>{name.toUpperCase()}</h3>
					</div>
					<p className='text-gray-700 mb-auto' dangerouslySetInnerHTML={{__html: text}}></p>
					{/* {source ? (
						<a
							className='text-contact font-bold mt-auto ml-auto mb-4 hover:underline underline-offset-4'
							href={source}
							onClick={getEmbed}
							target='_blank'
							rel='noopener noreferrer'>
							<FaLink className='inline-block align-middle' /> See Original
						</a>
					) : null} */}
				</>
			)}
			{isLoading && <p className='text-center'>Loading...</p>}
			{error && <p className='text-center text-red-600'>Sorry, there was an error loading the embed link.</p>}
		</>
	)
}

export default Testimonials
