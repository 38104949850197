import React from 'react'
// import './tiers.css'
// eslint-disable-next-line
import { simpleGalleryImages, standardGalleryImages, premiumGalleryImages, BGShapes, TriangleBG } from '../../../img'
// import DividerWave from '../../components/divider_wave/divider_wave'
import { useWhyDidYouUpdate } from '../../../utils/useWhyDidYouUpdate'
import TierComparisonTable from './TierComparisonTable'

const simplePic = simpleGalleryImages[0]
const standardPic = standardGalleryImages[3] //getRandomCaricature()
const premiumPic = premiumGalleryImages[0]

const Tiers = (props) => {
	const { id, forwardRef } = props
	useWhyDidYouUpdate('tiers', props)

	const tableData = {
		simple: {
			name: 'Simple',
			img: simplePic,
			features: ['Line Art', 'Black & White', 'One character only', 'Head and shoulders only', 'No background'],
			description: 'This basic tier includes options such as line art and black and white coloring.',
			priceClass: 'show25',
		},
		standard: {
			name: 'Standard',
			img: standardPic,
			features: ['All options of Simple tier, plus:', 'Colored artwork', 'One character only', 'Simple background', 'Additional details'],
			description:
				'This mid-range tier includes all the options of the Simple tier, as well as additional options such as colored artwork. This is the most popular tier among our customers.',
			priceClass: 'show35',
			most_populer: true,
		},
		premium: {
			name: 'Premium',
			img: premiumPic,
			features: ['All in Standard +', 'Complex coloring', 'Full body option', 'Detailed background', 'Video of drawing process'],
			description: 'This top tier includes all the options of the Standard tier, as well as advanced options such as a video of the drawing process.',
			priceClass: 'show100',
		},
	}
	return (
		<section
			style={{ backgroundImage: `url(${TriangleBG})` }}
			className='relative overflow-hidden select-none text-black z-other bg-main bg-left bg-cover bg-no-repeat bg-fixed'
			id={id}
			data-section={id}
			ref={forwardRef}>
			<div className='max-w-3xl relative flex-to-center mx-auto'>
				<p className='text-left font-adelez text-3xl sm:text-5xl pt-20'>
					<span className='leading-normal'>
						{"Bring your "}
						<br className='md:hidden' />
						{"character"}
					</span>
					<br />
					<span className='text-orange leading-normal'>
						{"to life "}
						<br className='md:hidden' />
					</span>
				</p>
			</div>
			{/* <DividerWave direction='down' /> */}
			<div className='flex justify-center flex-col'>
				<TierComparisonTable tableData={tableData} />
			</div>
		</section>
	)
}

export default Tiers
