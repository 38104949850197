// eslint-disable-next-line
import React, { useEffect, useState } from 'react'

import { shuffleArray } from '../../../utils'
import {
	Wakeup,
	LadyFan,
	Slowdown,
	Sparkly,
	simpleGalleryImages,
	premiumGalleryImages,
	standardGalleryImages,
	customGalleryImages,
	// eslint-disable-next-line no-unused-vars
	BGShapes,
	// eslint-disable-next-line no-unused-vars
	TriangleBG,
} from '../../../img'
import { useWhyDidYouUpdate } from '../../../utils/useWhyDidYouUpdate'
import GallerySection from './GallerySection'
// import DividerWave from '../../../components/divider_wave/divider_wave'

// import {scrollIntoView} from '../../../utils/scroll_into_view'
// import {useIntersectionObserver} from '../../utils/intersectionObserver'

// import {ReactFitty} from 'react-fitty'
// eslint-disable-next-line no-unused-vars
import { InView, useInView } from 'react-intersection-observer'
// eslint-disable-next-line no-unused-vars
import { isiOS } from '../../../utils/browserCheck'
import CustomSection from './customSection'
// import {useUser, useUserLoggedIn} from '../../../context/UserContext'

const simpleGalleryShuffeld = shuffleArray(simpleGalleryImages)
const standardGalleryShuffeld = shuffleArray(standardGalleryImages)
const premiumGalleryShuffeld = shuffleArray(premiumGalleryImages)
const customGalleryShuffeld = shuffleArray(customGalleryImages)

const simpleDescription =
	'Get your message across with a charming, well-designed cartoon, rendered in elegant lines and imbued with that classic "cartoony" feel!'
const standardDescription =
	'Elevate your cartoon to the next level with energetic watercolors that instantly capture attention and create a lasting impression!'
const premiumDescription =
	'Make a statement with a dynamic, painterly depiction of your designed cartoon - perfect for leaving a lasting impression on your audience!'
const customDescription =
	'Want something truly unique? Our custom-stylized cartoons combine expert design and dynamic, painterly effects to create a one-of-a-kind look that reflects your brand perfectly!'

const Gallery = (props) => {
	useWhyDidYouUpdate('Gallery', props)
	const { id, forwardRef } = props //, inView
	// const [currentTier, setCurrentTier] = useState('simple')
	const [shouldShowTip, setShouldShowTip] = useState(true)
	const [showTip, setShowTip] = useState(true)
	// const isLoggedIn = useUserLoggedIn()

	const tierList = {
		simple: {
			name: 'Simple',
			description: simpleDescription,
			doodle: Sparkly,
			pics: simpleGalleryShuffeld,
		},
		standard: {
			name: 'Standard',
			description: standardDescription,
			doodle: Slowdown,
			pics: standardGalleryShuffeld,
		},
		premium: {
			name: 'Premium',
			description: premiumDescription,
			doodle: Wakeup,
			pics: premiumGalleryShuffeld,
		},
	}
	const tierListCustom = {
		name: 'Custom',
		description: customDescription,
		doodle: LadyFan,
		pics: customGalleryShuffeld,
	}

	// const scrollToTier = (tier) => {
	// 	const tierEl = document.getElementById(tier)
	// 	if (tierEl) {
	// 		tierEl.scrollIntoView({
	// 			behavior: 'smooth',
	// 			block: 'start',
	// 			inline: 'start',
	// 		})
	// 	}
	// }

	// const changeTier = (newTier) => {
	// 	setCurrentTier(newTier)
	// 	setShouldShowTip(true)
	// 	setShowTip(true)
	// 	scrollToTier(tierList[newTier].name)
	// }

	// eslint-disable-next-line no-unused-vars
	// const GalleryButtons = () => (
	// 	<div id='tier-buttons' className={`z-30 pb-2`}>
	// 		{Object.keys(tierList).map((tier) => (
	// 			<button key={tier} onClick={() => changeTier(tier)} className={`tier-button shine-effect${tier === currentTier ? ' selected' : ''}`}>
	// 				<img src={tierList[tier].pics[0]} alt={tierList[tier].name} />
	// 				<div className='overlay'>{tierList[tier].name}</div>
	// 			</button>
	// 		))}
	// 	</div>
	// )
	// const CurrentTierDoodle = tierList[currentTier].doodle

	// useIntersectionObserver(forwardRef, '0px', 0, true, undefined, false, handleIntersection)
	return (
		<section
			id={id}
			data-section={id}
			ref={forwardRef}
			// style={{backgroundImage: `url(${BGShapes})`}}
			// style={{
			// 	backgroundImage: `${isiOS ? '' : `url(${BGShapes})`}`,
			// }}
			className={`flex relative flex-col justify-center min-h-screen z-[var(--zi-about)] bg-fixed bg-origin-border bg-main bg-left bg-cover bg-no-repeat pt-divider`}>
			{/* <div
				style={{
					backgroundImage: `url(${BGShapes})`,
					backgroundRepeat: 'no-repeat',
					backgroundPosition: 'center',
					backgroundSize: 'cover',
					position: 'fixed',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					zIndex: '-1',
				}}
			/> */}
			<div className='relative h-full items-center justify-center content-center flex flex-col'>
				<div className='title z-50 sticky top-0 text-bg_color bg-orange w-full'>
					<h1 className='text-center text-main bg-orange text-4xl p-3 font-adelez'>Gallery</h1>
				</div>
				<div className='overflow-x-hidden h-full w-full items-center justify-center content-center flex flex-col'>
					<div className='z-20 content-center mx-auto items-center overflow-x-visible w-full'>
						{Object.keys(tierList).map((tier) => (
							<section id={tierList[tier].name} key={tierList[tier].name} className='relative pt-20 justify-center items-center flex flex-col'>
								<GallerySection
									tierName={tierList[tier].name}
									Doodle={tierList[tier].doodle}
									description={tierList[tier].description}
									pics={tierList[tier].pics}
									// openOverlayModal={openOverlayModal}
									shouldShowTip={shouldShowTip}
									setShouldShowTip={setShouldShowTip}
									showTip={showTip}
									setShowTip={setShowTip}
								/>
							</section>
						))}
						{
							// isLoggedIn && (
							<section
								id={'Custom'}
								key={'Custom'}
								// style={{
								// 	backgroundImage: `${isiOS ? '' : `url(${TriangleBG})`}`,
								// }}
								className='relative justify-center items-center flex flex-col bg-fixed bg-origin-border bg-main bg-left bg-cover bg-no-repeat overflow-hidden'
							// className={`flex relative flex-col justify-center min-h-screen z-[var(--zi-about)]  pt-divider`}>
							>
								<CustomSection
									tierName={tierListCustom.name}
									Doodle={tierListCustom.doodle}
									description={tierListCustom.description}
									pics={tierListCustom.pics}
									// openOverlayModal={openOverlayModal}
									shouldShowTip={shouldShowTip}
									setShouldShowTip={setShouldShowTip}
									showTip={showTip}
									setShowTip={setShowTip}
								/>
							</section>
							// )
						}
					</div>
				</div>
				{/* <GalleryButtons /> */}
				{/* <DividerWave direction='up' className='fill-main' /> */}
			</div>
		</section>
	)
}

export default Gallery
