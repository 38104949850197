// eslint-disable-next-line
import React, {useState, useCallback} from 'react'
// import {useEventListener} from '../../utils/useEventListener'
import DividerWave from '../divider_wave/divider_wave'
import {isMobile} from '../../utils/cssHelpers'
// import scrollIntoView from '../../utils/scroll_into_view'
// import './navbar.css'

// import {RiMenu3Line, RiCloseLine} from 'react-icons/ri'
// eslint-disable-next-line
import {Logo, Lightbulb} from '../../img'
import {Burger} from '../burgur'
import {Link, useLocation} from 'react-router-dom'
import {useUser} from '../../context/UserContext'
// import {FaToolbox, FaUserSecret} from 'react-icons/fa'
// import {useNavigate} from 'react-router-dom'
// import {ReactFitty} from 'react-fitty'

function filterRoutes(routes, userRole) {
	const filteredRoutes = routes.filter((route) => {
		// Check if the route is navigable and the user role is included in the access list
		return route.isNavigable && route.accessList.includes(userRole)
	})
	// console.log(filteredRoutes)

	return filteredRoutes
}
const Navbar = ({
	// toggleTheme,
	// currentTheme,
	navOpen,
	toggleNav,
	routes,
}) => {
	const location = useLocation()
	const user = useUser()
	const userRole = user.role
	// const navigate = useNavigate()

	// const [isDarkMode, setDarkMode] = useState(currentTheme === 'dark')
	// const changeTheme = () => {
	// 	setDarkMode(!isDarkMode)
	// 	toggleTheme(currentTheme)
	// }

	// const ALLOWED_KEYS = ['Escape', 'h']
	const navMenuToggler = (url) => {
		// navigate(url)
		toggleNav()
		// document.body.classList.toggle('noscroll')
		if (isMobile())
			if (navOpen) document.body.classList.remove('noscroll')
			else document.body.classList.add('noscroll')
	}

	const NavLink = ({to, text, currentPage}) => {
		return (
			<li className={`md:translate-y-0 md:px-2`}>
				{/* <li className={`md:translate-y-0 md:px-2 ${currentPage ? 'underline' : ''}`}> */}
				<Link
					to={to}
					onClick={() => {
						navMenuToggler()
					}}
					className='md:hover-underline-animation'>
					{text}
				</Link>
			</li>
		)
	}
	return (
		<div id='nav' className={`fixed top-0 pointer-events-none h-navHeight w-[100vw] ${navOpen ? 'toggled' : ''}`}>
			<DividerWave direction='down' className='fill-main' />
			{/* dont remove!
                items-center - makes it verticly centered when flex-row (on larger the md screen size)
                justify-between - moves the logo and menu to the edges with the links in the middle
                pl-2 md:pl-8 - causing issues on safari
            */}
			<div className='relative items-center justify-between flex max-w-6xl mx-auto h-full w-full'>
				<Link to='/' className='logo fill-nav h-full ml-4 pointer-events-auto'>
					<Logo />
				</Link>
				<ul className={`links ${navOpen ? 'visible opacity-100' : 'invisible opacity-0'}`}>
					{filterRoutes(routes, userRole)
						// .map(
						// .filter((section) => location.pathname !== '/' && location.pathname !== section.href)
						//this removes the current link from the ui
						// .filter((section) => section.path !== location.pathname)
						.map((section, i) => (
							<NavLink to={section.path} text={section.navText} key={i} currentPage={section.path === location.pathname} />
						))}
					{/* {user && user.role !== 'guest' && <NavLink to={'/profile'} text={user.role !== 'guest' ? <FaUserSecret /> : 'Sign in'} key={'p'} />} */}
					{/* {user && user.role === 'admin' && <NavLink to={'/admin'} text={<FaToolbox />} key={'a'} />} */}
				</ul>
				{/* <Lightbulb className={`lightbulb${isDarkMode ? ' dark' : ''}${navOpen ? '' : ' hidden'}`} onClick={changeTheme} /> */}
				<Burger className={`fill-nav mr-4 pointer-events-auto ${navOpen ? 'as_xMark' : ''}`} onClick={navMenuToggler} />
			</div>
		</div>
	)
}

export default Navbar
