import {useContext, useEffect, useState} from 'react'
// import AnimatedNumbers from 'react-animated-numbers'
import {FaLock} from 'react-icons/fa'
import {OrderContext} from './OrderPage'
import MyAnimatedNumbers from './MyAnimatedNumbers'
import DataService from '../../services/DataService'
import {useNotification} from '../../context/NotificationContext'

const TotalPrice = () => {
	const {setToast} = useNotification()
	const {currentOrder, setOrderComplete, images, formik} = useContext(OrderContext)

	const [currentPrice, setCurrentPrice] = useState(currentOrder.total)

	useEffect(() => {
		setCurrentPrice(currentOrder.total)
	}, [currentOrder])

	const checkout = () => {
		setToast('This page is still under construction, please contact me from the contact section on the home page.', 'warning')
		// console.log('checkout btn pressed')
		// console.log(currentOrder)
		// // Prepare the data to be sent
		// const formData = new FormData()
		// // formData.append('currentOrder', JSON.stringify(currentOrder))
		// // Append the form fields from Formik to the FormData
		// Object.keys(formik.values).forEach((key) => {
		// 	formData.append(key, formik.values[key])
		// })
		// // Append the selected files to the form data
		// for (let i = 0; i < images.length; i++) {
		// 	formData.append('images', images[i])
		// }
		// // Make a POST request to the backend
		// DataService.order(formData)
		// 	// .then((response) => response.json())
		// 	.then((data) => {
		// 		// Process the response from the backend
		// 		console.log('Response from backend:', data)
		// 		setOrderComplete(true)
		// 	})
		// 	.catch((error) => {
		// 		console.error('Error sending data to backend:', error)
		// 	})
	}

	return (
		<div className='p-4 fixed bottom-0 right-0 flex flex-col text-white w-fit z-50'>
			<button
				type='submit'
				onClick={checkout}
				id='checkout-btn'
				className='bg-blue-600 p-2 md:p-4 align-right cursor-pointer hover:bg-blue-700 hover:text-2xl rounded-md hover:outline-none'>
				<h2 className='text-lg md:text-xl font-medium inline-flex'>
					<FaLock className='mr-3 mt-[2px]' />
					Send order:
				</h2>
			</button>
			<div className='bg-blue-400 p-2 md:p-4 w-fit mt-2 md:mt-4 ml-auto rounded-md'>
				<h3 className='text-md text-right font-medium inline-flex'>
					<p className='mr-2'>Price:</p>
					{`$`}
					<MyAnimatedNumbers number={currentPrice} />
				</h3>
			</div>
		</div>
	)
}

export default TotalPrice
