import {useContext, useEffect, useState} from 'react'
import {ImageFileSelector} from '../Common/ImageFileSelector'
import {OrderContext} from './OrderPage'
import {FaTimes} from 'react-icons/fa'

const ImagePreview = ({image, onRemove}) => {
	const handleRemove = () => {
		onRemove(image)
	}
	const [isHovered, setIsHovered] = useState(false)

	const handleHover = () => {
		setIsHovered(!isHovered)
	}
	return (
		<div className='relative w-full h-40 border-dashed border-2 border-gray-400 rounded-lg transition-all'>
			<div className='opacity-0 w-full h-full' onMouseEnter={handleHover} onMouseLeave={handleHover}></div>
			{isHovered && <FaTimes />}
			<div className={`w-full h-full cursor-pointer`} onClick={handleRemove}>
				{image && (
					<img
						className='max-w-full h-full rounded-lg object-contain'
						src={URL.createObjectURL(image)}
						alt='Preview'
						// onClick={() => fileInputRef.current.click()}
					/>
				)}
			</div>
		</div>
	)
}
const FileSelector = ({header}) => {
	const [selectedFiles, setSelectedFiles] = useState([])
	const {setImages, formik} = useContext(OrderContext)

	useEffect(() => {
		setImages(selectedFiles)
		formik.setFieldValue('images', [...selectedFiles])

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedFiles])
	return (
		<div className='flex flex-col pt-4'>
			<label htmlFor='files' className='mt-8'>
				{header}
			</label>

			{/* Render multiple ImageFileSelectors based on number of selected files */}
			<div className='w-fit flex flex-wrap relative m-1'>
				{selectedFiles.map((file, index) => (
					<div className='w-fit p-0 m-1' key={index}>
						<ImageFileSelector
							file={file}
							setFile={(newFile) => {
								const newFiles = [...selectedFiles]
								newFiles[index] = newFile
								setSelectedFiles(newFiles)
							}}
						/>
						{/* <ImagePreview
							image={file}
							onRemove={(removedImage) => {
								const updatedFiles = selectedFiles.filter((image) => image !== removedImage)
								setSelectedFiles(updatedFiles)
								formik.setFieldValue('selectedFiles', updatedFiles)
							}}
						/> */}
					</div>
				))}

				{/* Show one ImageFileSelector to select a new file */}
				{selectedFiles.length < 5 && (
					<ImageFileSelector
						file={null}
						setFile={(newFile) => {
							setSelectedFiles([...selectedFiles, newFile])
						}}
					/>
				)}
			</div>
		</div>
	)
}

export default FileSelector
