import React, {useEffect, useRef, useState} from 'react'
// import {Dropdown, FormControl} from 'react-bootstrap'
// import {isBrowser} from 'react-device-detect'

const Toggle = React.forwardRef(({children, onClick}, ref) => (
	// eslint-disable-next-line jsx-a11y/anchor-is-valid
	<a
		href=''
		ref={ref}
		onClick={(e) => {
			e.preventDefault()
			onClick(e)
		}}>
		{children} &#x25bc;
	</a>
))

const sharedState = {
	null: '',
}

const FilterableList = React.forwardRef(({children, className, settings, inputRef}, ref) => {
	const {share, id, enableFilter = true} = settings

	const [value, setValue] = useState(share ? sharedState[id] || '' : '')

	useEffect(() => {
		if (share && sharedState[id] !== value) {
			if (sharedState[id] === undefined) {
				sharedState[id] = ''
			}
			setValue(sharedState[id])
		}
		//added [...] might not be needed
	}, [share, id, value])

	return (
		<div ref={ref} style={{maxWidth: '100%', overflow: 'hidden'}} className={className}>
			{enableFilter && (
				<div className='dropdown-list-filter-container'>
					{/* <FormControl
						placeholder='Type to filter...'
						onChange={(e) => {
							const input = e.target.value.toLowerCase()
							setValue(input)
							if (share) {
								sharedState[id] = input
							}
						}}
						ref={inputRef}
						value={value}
					/> */}
				</div>
			)}
			<ul>{React.Children.toArray(children).filter((child) => !value || child.props.children.toLowerCase().includes(value))}</ul>
		</div>
	)
})

const DropdownFilterableList = ({header, children, onSelect, settings = {enableFilter: true, share: false, id: null}}) => {
	const [isActive, setIsActive] = useState(false)
	const elementRef = useRef()

	// useEffect(() => {
	// 	if (isActive && isBrowser) elementRef.current?.focus()
	// }, [isActive])

	const handleToggle = () => {
		setIsActive(!isActive)
	}

	return (
		<></>
		// <Dropdown onSelect={onSelect} onToggle={handleToggle}>
		// 	<Dropdown.Toggle as={Toggle}>{header}</Dropdown.Toggle>
		// 	<Dropdown.Menu as={FilterableList} inputRef={elementRef} settings={settings}>
		// 		{children}
		// 	</Dropdown.Menu>
		// </Dropdown>
	)
}

export default DropdownFilterableList
