import React, {useState} from 'react'
import {Deck} from './deck2'
// import {scrollIntoView} from '../../../utils/scroll_into_view'
import {FingerClickSwipe} from '../../../img'
import {ReactFitty} from 'react-fitty'
import {useNavigate} from 'react-router-dom'

const CTA = ({tierName, className}) => {
	const navigate = useNavigate()
	return (
		<a href={`#${tierName}`} className={`btn cta ${className}`} onClick={() => navigate('/order')}>
			Get your own!
		</a>
	)
}
const GallerySection = ({tierName, Doodle, pics, description, shouldShowTip, setShouldShowTip, showTip, setShowTip}) => {
	const [handClass, setHandClass] = useState('click')

	const handleMouseEnter = () => {
		if (shouldShowTip) {
			setShowTip(true)
			setHandClass('swipe')
		}
	}
	const handleMouseLeave = () => {
		if (shouldShowTip) {
			setShowTip(true)
			setHandClass('click')
		}
	}
	const handleTouchStart = () => {
		setShouldShowTip(false)
		setShowTip(false)
		setHandClass('swipe')
	}
	const handleClick = () => {
		setShouldShowTip(false)
		setShowTip(false)
		setHandClass('click')
	}
	const zoomOutShowTip = () => {
		// alert('test')
		setShouldShowTip(true)
		setShowTip(true)
		setHandClass('click')
	}

	return (
		<>
			{/* <h3 className='font-adelez text-3xl md:text-6xl lg:text-8xl text-orange'>{tierName}</h3> */}

			<ReactFitty maxSize={90} minSize={16} className='font-adelez px-4 text-orange'>
				{tierName}
			</ReactFitty>
			<div className='relative pt-10 pb-20 justify-center items-center flex flex-col md:flex-row'>
				<div className='relative h-full text-lg max-w-prose mx-auto flex flex-col justify-end items-center order-2 md:order-2'>
					<div className='flex items-center md:flex-col sm:mx-4 pb-6'>
						<p className='text-doodle max-w-sm text-center leading-7 px-4' dangerouslySetInnerHTML={{__html: description}}></p>
						<Doodle className='doodle hidden sm:block fill-cta h-full sm:max-h-[30vh] sm:w-auto pl-4 md:pt-4' />
					</div>
					<CTA tierName={tierName} className='mt-auto' />
				</div>
				<div
					className='relative px-4 sm:px-6 lg:mt-8 order-1 md:order-1'
					onMouseEnter={handleMouseEnter}
					onMouseLeave={handleMouseLeave}
					onTouchStart={handleTouchStart}
					onClick={handleClick}>
					<Deck pictures={pics} onDrag={handleClick} zoomOutShowTip={zoomOutShowTip} />
					{showTip && (
						<FingerClickSwipe
							className={`${handClass} text-gray-800 fill-orange hover:text-orange absolute w-32 h-32 mr-6 md:w-40 md:h-40 md:mr-16 inset-0 ml-auto mt-auto z-20 pointer-events-none`}
						/>
					)}
				</div>
				{/* <div className='block lg:absolute lg:inset-y-0 lg:h-full lg:w-full'> */}
			</div>
			{/* </div> */}
		</>
	)
}

export default GallerySection
