import {useContext} from 'react'
import {FaCheckCircle} from 'react-icons/fa'
import {OrderContext} from './OrderPage'

export const OrderSummery = () => {
	const {currentOrder, itemsList} = useContext(OrderContext)

	const optionsSummary = Object.entries(currentOrder).map(([key, value]) => {
		const option = itemsList[key]?.find((item) => item.slug === value.slug)
		if (!option) return null
		let optionText

		switch (key) {
			case 'wait':
				optionText = `Time:`
				break
			// add cases for other keys here
			default:
				optionText = `${key.charAt(0).toUpperCase() + key.slice(1)}:`
				break
		}
		return (
			<div key={key} className='p-2 flex items-center justify-left'>
				<FaCheckCircle className='pr-2 text-green' size={28} />
				{/* <div className='mx-2'> */}
				<p className='pl-2 text-lg font-medium'>
					{optionText}
					<span className='text-sm text-gray-500'>
						{' '}
						{option.name}
						{/* (${option.price}) */}
					</span>
				</p>
				{/* <p className='text-xs text-gray-500 font-regular'>{option.description}</p> */}
				{/* </div> */}
			</div>
		)
	})

	return (
		<div className='p-4 bg-white rounded-md shadow-md m-4'>
			<h2 className='text-2xl font-bold mb-4'>Order Summary</h2>
			{optionsSummary}
			<div key={'people'} className='p-2'>
				<p className='text-lg font-medium'>Amount of people: x{currentOrder.people.amount}</p>
				{/* <p className='text-sm text-gray-500'></p> */}
			</div>
			<hr className='my-4' />
			<p className='text-lg font-medium'>Total: ${currentOrder.total?.toFixed(2)}</p>
		</div>
	)
}
