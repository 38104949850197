// eslint-disable-next-line no-unused-vars
import React, {useState} from 'react'
import {useFormik} from 'formik'
import {useNotification} from '../../context/NotificationContext'
// import * as Yup from 'yup'
// import './BasicAuthPage.css'

const EmptyWidget = <></>

const BasicAuthPage = ({title, initialValues, onSubmit, postSubmit, footer = EmptyWidget, forms, buttonText, postFormWidget = EmptyWidget}) => {
	// const [result, setResult] = useState(null)
	const {setToast} = useNotification()

	const formik = useFormik({
		initialValues: initialValues,
		onSubmit: async (values, {setSubmitting}) => {
			setSubmitting(true)
			try {
				const data = await onSubmit?.(values)
				// setResult({isSuccessful: true, message: data?.message || 'Success'})
				setToast('Welcome ' + values.email, 'success')
				setToast(data?.message)
			} catch (e) {
				// setResult({isSuccessful: false, message: e})
				setToast(e, 'error')
			} finally {
				setSubmitting(false)
				postSubmit?.()
			}
		},
	})
	return (
		<div className='auth-page-layout'>
			<form noValidate onSubmit={formik.handleSubmit} className='flex flex-col items-center justify-center h-screen'>
				<label className='my-2 flex flex-col md:flex-row justify-between md:w-72'>
					<span className='p-1'>Email:</span>
					<input
						id='email'
						type='email'
						value={formik.values.email}
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						className='md:mx-2 p-1 border rounded-md'
						autoComplete='on'
					/>
				</label>
				<label className='my-2 flex flex-col md:flex-row justify-between md:w-72'>
					<span className='p-1'>Password:</span>
					<input
						id='password'
						type='password'
						value={formik.values.password}
						onBlur={formik.handleBlur}
						onChange={formik.handleChange}
						// placeholder={form.placeholder}
						className='md:mx-2 p-1 border rounded-md'
						autoComplete='on'
					/>
				</label>
				{/* <div className='font-regular ml-4 text-red self-start mt-1 transition-all'>
					{formik.touched.password && formik.errors.password ? formik.errors.password : ''}
				</div> */}
				<button
					type='submit'
					// onClick={() => {
					// 	document.activeElement.blur()
					// }}
					disabled={formik.isSubmitting}
					className='my-2 px-4 py-2 bg-doodle text-white rounded-md'>
					{formik.isSubmitting ? 'Submitting' : buttonText}
				</button>
			</form>
		</div>
	)
}

export default BasicAuthPage
