// import React, {useState} from 'react'
import React, {useState, useCallback} from 'react'
// import React, {useState, useCallback, useRef, useEffect, useMemo} from 'react'
// import './cariAnimations.css'
import {FormContainer} from './contactForm'
// import './contact.css'
// import './contactPref.css'
import {SecretaryStandby, TakingNotes, SendNote} from '../../../img'
import DividerWave from '../../../components/divider_wave/divider_wave'
import {BiMessageDetail} from 'react-icons/bi'
import {FaInstagram, FaTelegram} from 'react-icons/fa'
import {MdOutlineMarkEmailRead} from 'react-icons/md'
// MdAlternateEmail
import {useWhyDidYouUpdate} from '../../../utils/useWhyDidYouUpdate'

//todo add some shiny emoji
// import '../../../utils/foucuss'
const contact_text = "Let's chat and bring your vision to life! Just fill out this shiny form to get the ball rolling."

// const Focusss = () => {
// 	const SPACING = 16
// 	const RADIUS = 8
// 	const CHECK_RADIUS = RADIUS * 1.8
// 	const CHECK_SIZE = 10
// 	const CHECK_LENGTH = Math.ceil(10 * 1.8)
// 	const TAIL_LENGTH = 8

// 	const rootStyles = getComputedStyle(document.documentElement)
// 	const HEAD_COLOR = rootStyles.getPropertyValue('--focus-head-color')
// 	const TAIL_COLOR = rootStyles.getPropertyValue('--focus-tail-color')

// 	const head = useMemo(() => {
// 		return {r: RADIUS, tr: RADIUS}
// 	}, [RADIUS])
// 	const tail = useMemo(() => [], [])
// 	// const [checkmarks, {set}] = useMap([])

// 	const [checkmarks, setCheckmarks] = useState(new Map())

// 	let width = useRef(),
// 		height = useRef(),
// 		dpr = useRef(),
// 		currentFocus = useRef(),
// 		canvas = useRef(),
// 		context = useRef()

// 	const resize = useCallback(() => {
// 		dpr.current = window.devicePixelRatio || 1

// 		width.current = window.innerWidth
// 		height.current = window.innerHeight

// 		canvas.current.width = width.current * dpr.current
// 		canvas.current.height = height.current * dpr.current
// 		context.current.scale(dpr.current, dpr.current)
// 	}, [context])
// 	const paint = useCallback(() => {
// 		context.current.clearRect(0, 0, width.current, height.current)

// 		if (currentFocus.current) {
// 			// Add to the tail
// 			tail.push({...head})
// 			if (tail.length > TAIL_LENGTH) tail.shift()

// 			// Paint the tail
// 			if (tail.length > 3) {
// 				context.current.beginPath()
// 				context.current.moveTo(tail[0].x, tail[0].y)

// 				for (let i = 2; i < tail.length - 2; i++) {
// 					const p1 = tail[i]
// 					const p2 = tail[i + 1]

// 					context.current.quadraticCurveTo(p1.x, p1.y, (p1.x + p2.x) / 2, (p1.y + p2.y) / 2)
// 					context.current.quadraticCurveTo(tail[i].x, tail[i].y, tail[i + 1].x, tail[i + 1].y)
// 				}

// 				context.current.lineWidth = RADIUS
// 				context.current.lineCap = 'round'
// 				context.current.strokeStyle = TAIL_COLOR
// 				context.current.stroke()
// 			}

// 			head.tr = currentFocus.current.classList.contains('valid') ? CHECK_RADIUS : RADIUS

// 			// Animate the head towards its target values
// 			head.x += (head.tx - head.x) * 0.2
// 			head.y += (head.ty - head.y) * 0.2
// 			head.r += (head.tr - head.r) * 0.2

// 			head.vx *= 0.8
// 			head.x += head.vx

// 			context.current.beginPath()
// 			context.current.arc(head.x, head.y, head.r, 0, Math.PI * 2)
// 			context.current.fillStyle = HEAD_COLOR
// 			context.current.fill()
// 		}

// 		// Paint checkmarks
// 		for (let check in checkmarks) {
// 			const {inputElement, checkmark} = check
// 			checkmark.v += (checkmark.tv - checkmark.v) * 0.2

// 			if (checkmark.v > 0.05) {
// 				const midX = inputElement.offsetLeft - CHECK_SIZE / 2 - SPACING - 3
// 				const midY = inputElement.offsetTop + inputElement.offsetHeight / 2 + 1

// 				context.current.save()
// 				context.current.beginPath()

// 				context.current.moveTo(midX + CHECK_SIZE / 2, midY - CHECK_SIZE / 2)
// 				context.current.lineTo(midX - 1, midY + CHECK_SIZE / 2 - 1)
// 				context.current.lineTo(midX - CHECK_SIZE / 2, midY)
// 				context.current.lineWidth = 3
// 				context.current.lineCap = 'round'
// 				context.current.lineJoin = 'round'
// 				context.current.setLineDash([CHECK_LENGTH, CHECK_LENGTH])
// 				context.current.lineDashOffset = CHECK_LENGTH + Math.round(checkmark.v * CHECK_LENGTH)

// 				context.current.globalCompositeOperation = 'lighter'
// 				context.current.strokeStyle = '#555'
// 				context.current.stroke()

// 				context.current.globalCompositeOperation = 'overlay'
// 				context.current.strokeStyle = '#fff'
// 				context.current.stroke()
// 				context.current.restore()
// 			}
// 		}
// 	}, [CHECK_LENGTH, CHECK_RADIUS, HEAD_COLOR, TAIL_COLOR, checkmarks, context, head, tail])
// 	const animate = useCallback(() => {
// 		paint()
// 		requestAnimationFrame(animate)
// 	}, [paint])
// 	const focus = useCallback(
// 		(element) => {
// 			const previousFocus = currentFocus.current

// 			if (element) currentFocus.current = element

// 			if (!currentFocus.current || !currentFocus.current.matches('input, button')) return

// 			head.tx = currentFocus.current.offsetLeft - SPACING - RADIUS
// 			head.ty = currentFocus.current.offsetTop + currentFocus.current.offsetHeight / 2

// 			// Skip animation on first focus
// 			if (isNaN(head.x)) {
// 				head.x = head.tx
// 				head.y = head.ty
// 			}

// 			// Bounce leftward when focus changes
// 			if (currentFocus.current !== previousFocus) {
// 				head.vx = -8 - Math.abs(head.tx - head.x) / 5
// 			}
// 			// }
// 		},
// 		[head]
// 	)
// 	// function validate(element) {
// 	// 	let valid = false

// 	// 	switch (element.getAttribute('type')) {
// 	// 		case 'email':
// 	// 			valid = /(.+)@(.+){2,}\.(.+){2,}/.test(element.value)
// 	// 			break
// 	// 		case 'password':
// 	// 			valid = element.value.length > 6
// 	// 			break
// 	// 		default:
// 	// 			valid = element.value.length > 0
// 	// 			break
// 	// 	}

// 	// 	element.classList.toggle('valid', valid)

// 	// 	checkmarks.get(element).tv = valid ? 1 : 0
// 	// }

// 	useEffect(() => {
// 		if (canvas.current) {
// 			context.current = canvas.current.getContext('2d')

// 			resize()
// 			animate()

// 			document.body.addEventListener('focus', (event) => focus(event.target), true)
// 			// document.body.addEventListener('input', (event) => validate(event.target), true)
// 			document.querySelectorAll('input:not([type="checkbox"])').forEach((input) => {
// 				// setCheckmarks({inputElement: input, checkmark: {v: 0, tv: 0}})
// 				setCheckmarks((prevMap) => {
// 					const nextMap = new Map(prevMap)
// 					nextMap.set(input, {v: 0, tv: 0})
// 					return nextMap
// 				})
// 			})
// 			window.addEventListener('resize', () => {
// 				requestAnimationFrame(() => {
// 					resize()
// 					focus()
// 					paint()
// 				})
// 			})

// 			window.addEventListener('scroll', () => {
// 				requestAnimationFrame(() => {
// 					focus()
// 					paint()
// 				})
// 			})
// 			// do something here with the canvas
// 		}
// 	}, [animate, focus, paint, resize])
// 	return <canvas ref={canvas} className='z-50 absolute top-0 left-0 h-full w-full pointer-events-none'></canvas>
// 	// document.querySelectorAll( 'input' )[0].focus();
// }
// eslint-disable-next-line no-unused-vars
const FieldOptions = ({setPref}) => {
	return (
		<fieldset name='pref_method' className='flex-to-center p-4' defaultValue='' onChange={setPref}>
			{/* <legend>Choose a Contact method:</legend> */}
			<input type='radio' id='email' name='method' />
			<label htmlFor='email'>
				<MdOutlineMarkEmailRead className='icon' />
				<h6>Email</h6>
			</label>
			<input type='radio' id='instagram' name='method' />
			<label htmlFor='instagram'>
				<FaInstagram className='icon' />
				<h6>Instagram</h6>
			</label>
			<br />
			<input type='radio' id='telegram' name='method' />
			<label htmlFor='telegram'>
				<FaTelegram className='icon' />
				<h6>Telegram</h6>
			</label>
			<input type='radio' id='direct' name='method' />
			<label htmlFor='direct'>
				<BiMessageDetail className='icon' />
				{/* <MdAlternateEmail className='icon' /> */}
				<h6>Stay on site</h6>
			</label>
		</fieldset>
	)
}

// const getNumBetween = (min, max) => Math.floor(Math.random() * (max - min) + min)
const Contact = (props) => {
	const {id, forwardRef} = props
	// const {id, forwardRef, updateFormValues, formValues} = props
	useWhyDidYouUpdate('Contact', props)

	// const [showEmail, setShowEmail] = useState(true)
	const [selectedSubject, setSelectedSubject] = useState(false)
	//is user typing
	// const [typingCount, setTypingCount] = useState(0)
	// const [writingTimer, setWritingTimer] = useState(null)

	// eslint-disable-next-line no-unused-vars
	const [messageSent, setMessageSent] = useState(false)

	const [isTyping, setIsTyping] = useState(false)

	// const setPref = (event) => {
	// 	// console.log('setPref event', event.target)
	// 	// const {id, name} = event.target
	// 	// console.log('setPref event name', id, name)

	// 	setSelectedSubject(true)
	// 	// handleFormChange(event)
	// }

	const handleFocus = () => {
		setIsTyping(true)
	}
	const handleBlur = () => {
		setTimeout(() => setIsTyping(false), 500)
	}
	const handleFormChange = (event) => {
		// console.log('Contact handleFormChange name', event.target.name)
		// updateFormValues({[event.target.name]: event.target.name})
		// console.log('handleFormChange formValues', formValues)
		handleFocus()
		setTimeout(() => handleBlur, 1500)

		// making sure the typing count doesnt get too high
		// setTypingCount((typingCount) => (typingCount <= 2 ? typingCount + 1 : typingCount))
		// if (writingTimer) {
		// 	clearTimeout(writingTimer)
		// 	setWritingTimer(null)
		// }

		// const newWritingTimer = setTimeout(() => {
		// 	setTypingCount(0)
		// }, getNumBetween(1, 2) * (typingCount + 1) * 1000)
		// setWritingTimer(newWritingTimer)

		if (event.target.name === 'subject') setSelectedSubject(true)
	}
	const SecretaryImage = useCallback(
		({className}) => {
			if (!selectedSubject)
				return (
					<div className={className}>
						<SecretaryStandby />
					</div>
				)
			else if (messageSent)
				return (
					<div className={className}>
						<SendNote />
					</div>
				)
			else {
				return (
					<div id='writingSec' className={className}>
						<TakingNotes />
					</div>
				)
			}
		},
		[selectedSubject, messageSent]
	)
	return (
		<section className='z-contact relative bg-offwhite flex-to-center flex-col' id={id} data-section={id} ref={forwardRef}>
			{/* <Focusss /> */}

			{/* <DividerWave direction='up' className='-translate-y-full fill-offwhite' /> */}
			<h1 className='text-5xl text-center lg:text-left pb-6 text-contact my-4 font-adelez'>Contact</h1>
			<div className='relative max-w-6xl mx-auto px-6 flex-to-center flex-col md:flex-row'>
				<div className='form_section transition-all duration-[5s     ]'>
					{/* <label>Choose a Contact method:</label> */}
					{!messageSent && <p className='text-md text-contact mb-8'>{contact_text}</p>}
					<FormContainer
						// formValues={formValues}
						// updateFormValues={updateFormValues}
						contactMethod={selectedSubject}
						onChange={handleFormChange}
						setMessageSent={setMessageSent}
						messageSent={messageSent}
						handleFocus={handleFocus}
						handleBlur={handleBlur}
					/>
				</div>
				<div className='flex self-end justify-end bottom-0 right-0 relative pt-6 md:pt-0 ml-auto md:sticky'>
					<SecretaryImage className={`doodle h-full z-10 w-1/2 md:max-w-lg max-w-none ml-auto min-w-[min(200px, 30vw)] ${isTyping ? ' drawing' : ''}`} />
				</div>
			</div>
			<DividerWave direction='down' className='translate-y-[99%] fill-offwhite h-[0]' />
		</section>
	)
}

export default Contact
