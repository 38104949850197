import axios from 'axios'
import React, {useState} from 'react'
import {apiBaseUrl} from '../../utils/config'

const ImageUploader = () => {
	const [image, setImage] = useState(null)
	const [uploadedFile, setUploadedFile] = useState(null)

	const handleImageChange = (e) => {
		if (e.target.files[0]) {
			setImage(e.target.files[0])
		}
	}

	const handleUpload = () => {
		const formData = new FormData()
		formData.append('file', image)

		axios
			.post(`${apiBaseUrl}/upload_drive`, formData)
			// .post(`${apiBaseUrl}/adelez/contact`, values)
			.then((res) => {
				setUploadedFile(res.data)
			})
			.catch((e) => {
				console.error('Error uploading image:', e)
			})
	}

	return (
		<div className='flex-to-center flex-col my-auto'>
			<div>
				<input type='file' required multiple onChange={handleImageChange} />
				<button onClick={handleUpload}>Upload</button>
			</div>
			{uploadedFile &&
				uploadedFile.forEach((file) => (
					<img className='max-w-[500px] h-auto' alt={file.name} src={file.url} />
					// <img className='max-w-[500px] h-auto' alt={file.name} src={`https://drive.google.com/uc?export=view&id=${file.id}`} />
				))}
		</div>
	)
}

export default ImageUploader
