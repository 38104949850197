import React, {useContext} from 'react'
import {ListOfOptions} from './ImageAndDescription'
import {OrderContext} from './OrderPage'

const OrderSelectionSection = ({category, header}) => {
	const {itemsList} = useContext(OrderContext)

	const items = itemsList[category]
	// const minPrice = Math.min(...items.map((item) => item.price))

	return (
		<div className='pt-4'>
			<h2 className='mt-8'>
				{header}
				{/* <span className='text-gray-500 text-xs ml-2'>(Starts at: ${minPrice})</span> */}
			</h2>
			<ListOfOptions items={items} category={category} />
		</div>
	)
}

export default OrderSelectionSection
