import React, {useMemo} from 'react'
// import './hero.css'
import {getRandomCaricature, CaricaturesText} from '../../../img'
import DividerWave from '../../../components/divider_wave/divider_wave'
import {useWhyDidYouUpdate} from '../../../utils/useWhyDidYouUpdate'
// eslint-disable-next-line no-unused-vars
import {useStateWithHistory, useWindowSize} from 'react-use'
// import postRequest from '../../../utils/fetch'
import {scrollIntoView} from '../../../utils/scroll_into_view'
// import {apiBaseUrl} from '../../../utils/config'
import {useNavigate} from 'react-router-dom'
// import ScrollToTop from '../../../utils/scrollToTop'

// const imageAmount = 6
// const images = getRandomCaricature(imageAmount)

const Hero = (props) => {
	useWhyDidYouUpdate('Hero', props)
	const {id, forwardRef} = props
	const {width} = useWindowSize()

	const isMobile = width < 768
	const imageCount = isMobile ? 4 : 6
	const imageFiles = useMemo(() => getRandomCaricature(imageCount), [imageCount])

	const images = []
	for (let i = 0; i < imageFiles.length; i++) {
		images.push(
			<div className='flex-[50%] md:flex-[25%]' key={i}>
				<img src={imageFiles[i]} alt={''} key={i} className='w-full h-full object-cover' />
			</div>
		)
	}
	const navigate = useNavigate()

	const handleOrderClick = () => {
		navigate('/order')
	}

	return (
		// TODO return sticky and z-hero later
		<header className='relative w-[100vw] min-h-vh-90' id={id} data-section={id} ref={forwardRef}>
			{/* <ScrollToTop /> */}
			<div className='relative z-other bg-black'>
				<div className='images stick z-10 flex flex-wrap px-0 w-full h-hero overflow-hidden'>{images}</div>
				{/* <div className='images stick absolute inset-0 z-0 flex flex-wrap px-0 w-full h-hero'>{images}</div> */}

				{/* <div className='text stick flex-to-center left-0 right-0 h-hero max-w-3xl m-auto pointer-events-none'>
				<CaricaturesText className='w-full' />
			</div> */}
				<div className='text stick z-40 absolute inset-0 flex flex-col items-center justify-center max-w-3xl m-auto'>
					{/* need to add width here for safari... */}
					<CaricaturesText className='w-full max-h-[50vh] pointer-events-none max-w-2xl px-8 pb-6' />
					<div className='flex items-start justify-center pointer-events-auto text-white z-40'>
						<button className='rounded bg-doodle m-2 p-2 md:m-4 md:p-4' onClick={() => scrollIntoView(null, '#gallery')}>
							Gallery
						</button>
						<button className='rounded bg-doodle m-2 p-2 md:m-4 md:p-4 shine' onClick={handleOrderClick}>
							Order now
							{/* (test button for now) */}
						</button>
					</div>
				</div>
				<div className='overlay_main stick z-30' />

				<div className='overlay_half z-50 pointer-events-none' />
			</div>
			<DividerWave direction='down' className='translate-y-[-1%] fill-black' />
		</header>
	)
}

export default Hero
