// import axios from 'axios'
import jwt_decode from 'jwt-decode'
// import requestFactory from './RequestFactory'
// import {apiBaseUrl} from '../utils/config'
import requestFactory from './RequestFactory'

const loginRequest = requestFactory.post('/login')

const login = (email, password) => {
	return loginRequest({
		email,
		password,
	}).then((data) => {
		if (!data) return
		let user = null
		const token = data.token
		if (token) {
			const decodedToken = jwt_decode(token)

			user = {
				id: decodedToken.id,
				username: decodedToken.username,
				email: decodedToken.email,
				role: decodedToken.role,
				token: token,
			}
		}
		return user
	})
}

const registerRequest = requestFactory.post('/register')

const register = (username, email, password) => {
	// const formData = {
	// 	username,
	// 	email,
	// 	password,
	// }
	// return axios.post(`${apiBaseUrl}/register`, formData)
	return registerRequest({
		username,
		email,
		password,
	}).then((data) => {
		if (!data) return
		let user = null
		const token = data.token
		if (token) {
			const decodedToken = jwt_decode(token)

			user = {
				id: decodedToken.id,
				username: decodedToken.username,
				email: decodedToken.email,
				role: decodedToken.role,
				token: token,
			}
		}

		return user
	})
}

const AuthService = {
	login,
	register,
}

export default AuthService
