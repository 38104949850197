import React, {useState} from 'react'

// import CreateOrEditCategoryModal from './CreateOrEditCategoryModal'
// import ConfirmationModal from '../ConfirmationModal'
import {CustomTable} from '../../Common/CustomTable/CustomTable'
// import {usecategoriesDictionary} from '../hooks/usecategoriesDictionary'
// import {FaPencilAlt, FaPlusCircle, FaTimesCircle} from 'react-icons/fa'
import {useAdminData} from '../../../context/AdminDataContext'
import DataService from '../../../services/DataService'
import {useNotification} from '../../../context/NotificationContext'

// const DeleteCategoryModal = ({show, onHide, onSubmit}) => (
// 	<ConfirmationModal show={show} onHide={onHide} onConfirm={onSubmit} title={'Confirm removing'} />
// )

// const UnbindCategoryModal = ({show, onHide, onSubmit}) => (
// 	<ConfirmationModal show={show} onHide={onHide} onConfirm={onSubmit} title={'Confirm unbind'} />
// )

const EditCategories = () => {
	const [db] = useAdminData()
	const [newCategory, setNewCategory] = useState('')
	const {setToast} = useNotification()

	const handleAdd = async () => {
		if (newCategory) await DataService.addCategory({name: newCategory}).then(() => setNewCategory(''))
		else setToast('missing text?')
	}

	const handleEdit = async ({categoryId, name, address}) => {
		// await DataService.editCategory({categoryId, name, address})
	}

	const handleDelete = async ({categoryId}) => {
		// await DataService.removeCategory({categoryId})
	}
	const handleUnbind = async ({categoryId}) => {
		// await DataService.unbindCloverData({categoryId})
	}

	// onSubmit: (args) => handleEdit({categoryId, ...args}),
	// onSubmit: () => handleDelete({categoryId}),
	// onSubmit: () => handleUnbind({categoryId}),
	const a = [{name: 'Premium', createdAt: '2023-06-04T07:39:44.633Z', updatedAt: '2023-06-04T07:39:44.633Z', id: '647c3fc0286f49e320597010'}]
	const categoryPrettyNames = db.categories.map((c) => [c.id, c.name])

	const categoryRule = {
		header: 'categories',
		sorters: [
			// {
			// 	name: 'By time created',
			// 	sort: (l, r) => Date.parse(db.categories[l]?.createdAt) - Date.parse(db.categories[r]?.createdAt),
			// },
			// {
			// 	name: 'By name',
			// 	sort: (l, r) => categoryPrettyNames[l].localeCompare(categoryPrettyNames[r]),
			// },
			// {
			// 	name: 'By amount of machines',
			// 	sort: (l, r) => db.categories[r]?.machines.length - db.categories[l]?.machines.length,
			// },
		],
		test: (enteredText, id) => categoryPrettyNames[id].toLowerCase().includes(enteredText.toLowerCase()),
		cellWidget: (category) => (
			<>
				{/* {categoryPrettyNames[category]} */}
				{db.categories[category].name}
				<br />
				{/* Has {db.categories[cat]?.machines.length} caricatures */}
			</>
		),
	}

	const actionRule = {
		header: 'Actions',
		cellWidget: (category) => (
			<>
				test
				{/* <Button
					variant='light'
					onClick={() => {
						CategoryAction.edit(category)
					}}>
					<FaPencilAlt />
					Edit
				</Button>
				<Button
					variant='danger'
					onClick={() => {
						CategoryAction.remove(category)
					}}>
					<FaTimesCircle /> Delete
				</Button> */}
				{/* <Button
					variant='info'
					disabled={!db.categories[category].cloverMerchant}
					onClick={() => {
						CategoryAction.unbind(category)
					}}>
					<StopIcon size={16} verticalAlign='middle' /> Unbind
				</Button> */}
			</>
		),
		filters: [
			{
				name: 'Show all',
				filter: () => true,
			},
			// {
			// 	name: 'Clover binded',
			// 	filter: (category) => !!db.categories[category].cloverMerchant,
			// },
			// {
			// 	name: 'Clover not binded',
			// 	filter: (category) => !db.categories[category].cloverMerchant,
			// },
		],
	}

	return (
		<>
			{db.categories ? (
				<>
					<CustomTable
						data={Object.keys(db.categories)}
						rules={[categoryRule, actionRule]}
						specialRows={() => (
							<tr key='Add category'>
								<td>New category</td>
								<td>
									{/* <FaPlusCircle /> */}
									<input
										className='border border-spacing-1 bg-slate-400'
										onChange={(event) => {
											setNewCategory(event.target.value)
										}}
										value={newCategory}></input>
									<button onClick={handleAdd}>Add</button>
								</td>
							</tr>
						)}
					/>
					{/* <modal.component show={isShowing} onHide={handleClose} onSubmit={modal.onSubmit} /> */}
				</>
			) : (
				<>no categories yet</>
			)}
		</>
	)
}

export default EditCategories
