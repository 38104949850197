import {useState} from 'react'
// import './AdminTabs.css'

const AdminTabs = ({tabs}) => {
	const [activeTab, setActiveTab] = useState(0)

	return (
		<div className='admin-tabs'>
			<div className='flex flex-col'>
				<nav className='flex'>
					{tabs.map((entry, index) => (
						<button
							key={index}
							className={`py-2 px-4 text-left text-sm font-medium ${activeTab === index ? 'bg-gray-200' : ''}`}
							onClick={() => setActiveTab(index)}>
							{entry.name}
						</button>
					))}
				</nav>
				<div className='flex-grow'>
					{tabs.map((entry, index) => (
						<div key={index} className={`${activeTab === index ? '' : 'hidden'}`}>
							<entry.component />
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default AdminTabs
