import {useContext, useEffect, useState} from 'react'
import {OrderContext} from './OrderPage'
// eslint-disable-next-line no-unused-vars
// import MyAnimatedNumbers from './MyAnimatedNumbers'
const ImageAndDescription = ({item, category, minPrice, handleChange}) => {
	// eslint-disable-next-line no-unused-vars
	const {calcPriceDiff, currentOrder} = useContext(OrderContext)

	const itemPrice = item.price
	// const {priceDiff, symbol} = calcPriceDiff(itemPrice, currentOrder[category].price, minPrice)
	const name = item.name
	const imageUrl = item.image
	const description = item.description
	const selected = currentOrder[category].slug === item.slug

	// useEffect(() => {
	// 	if (currentOrder[category].price === itemPrice) handleChange(item)
	// }, [])

	return (
		<>
			<label className='flex justify-start items-center' htmlFor={category + name}>
				<img className='exampleImage' src={imageUrl} alt={name} />
				<div className='flex flex-col'>
					<h1 className='flex sm:items-center flex-col sm:flex-row'>
						<span className=''>{name} </span>
						<span className='text-gray-500 text-xs sm:pl-2'>{selected ? <>{'(selected)'}</> : <>{`($${itemPrice})`}</>}</span>
					</h1>
					<p className='font-regular'>{description}</p>
				</div>
			</label>
			<input
				className='hidden'
				type='radio'
				name={category}
				id={category + name}
				value={name}
				checked={currentOrder[category].name === name}
				onChange={() => handleChange(item)}
			/>
		</>
	)
}

export const ListOfOptions = ({items, category}) => {
	const {currentOrder, setCurrentOrder, formik} = useContext(OrderContext)

	const [choice, setChoice] = useState({})
	const handleSelection = (item) => {
		const newChoice = {price: item.price, slug: item.slug}
		setCurrentOrder({...currentOrder, [category]: newChoice})
	}
	useEffect(() => {
		const selectedSlug = currentOrder[category].slug
		const selectedItem = items.find((item) => item.slug === selectedSlug)
		setChoice(selectedItem || {})
		formik.setFieldValue(category, selectedItem)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentOrder])
	const minPrice = Math.min(...items.map((item) => item.price))

	return (
		<ul>
			{items
				.sort((a, b) => a.price - b.price)
				.map((item, index) => {
					// console.log('item, index', item, index)
					return (
						<li key={index} className={`orderOption${choice?.slug === item.slug ? ' selected shine-effect' : ''}`}>
							<ImageAndDescription item={item} category={category} minPrice={minPrice} handleChange={handleSelection} />
						</li>
					)
				})}
		</ul>
	)
}
