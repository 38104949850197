// eslint-disable-next-line no-unused-vars
import {useContext, useEffect, useState} from 'react'
import {FaClock} from 'react-icons/fa'
import {OrderContext} from './OrderPage'

const WaitSelection = ({header}) => {
	const {currentOrder, itemsList, setCurrentOrder, formik} = useContext(OrderContext)
	const handleChange = (item) => {
		// console.log('WaitSelection handleChange', item)
		const newChoice = {price: item.price, slug: item.slug}
		if (newChoice.price !== currentOrder.wait.price) {
			setCurrentOrder({...currentOrder, wait: newChoice})
			formik.setFieldValue('time', currentOrder.wait)
		}
	}

	// Sort wait time options by price
	const waitTimeOptions = itemsList.wait
		.sort((a, b) => a.price - b.price)
		.map((option) => (
			<label key={option.slug} htmlFor={option.slug} className={`wait-option ${option.slug === currentOrder.wait.slug ? 'selected shine-effect' : ''}`}>
				<input
					id={option.slug}
					type='radio'
					name='wait'
					value={option.slug}
					checked={option.slug === currentOrder.wait.slug}
					onChange={() => handleChange(option)}
					className='hidden'
				/>
				<div className='flex items-center p-2'>
					<FaClock className='w-6 h-6 mr-2 text-gray-500' />
					<div>
						<div className=''>
							{option.name}
							{option.price !== 0 && (
								<span className='text-sm text-gray-500'>{option.price > 0 ? ` +$${option.price}` : ` -$${Math.abs(option.price)}`}</span>
							)}
						</div>
						<span className=' font-thin text-xs'> {option.description}</span>
					</div>
				</div>
			</label>
		))

	return (
		<div className='pt-8'>
			<h2 className='text-xl'>{header}</h2>
			{waitTimeOptions}
		</div>
	)
}

export default WaitSelection
