import React, {useEffect, useState} from 'react'
// import {useMutation} from '@tanstack/react-query'
// import DataService from '../../services/DataService'
import UploadButton from './UploaderButton'
import {MyLink} from '../../Common/MyLink'
import {TagsList} from '../../../utils/TagsList'
import CreatableSelect from 'react-select/creatable'
import makeAnimated from 'react-select/animated'
import {tagOptions} from '../TagsOptions'
import {ImageFileSelector} from '../../Common/ImageFileSelector'
import {useQueryClient} from '@tanstack/react-query'
const animatedComponents = makeAnimated()

const UploadImageForm = () => {
	const [file, setFile] = useState(null)
	const [serverResponse, setServerResponse] = useState(null)

	// const [options, setOptions] = useState(tagOptions)
	const [filteredOptions, setFilteredOptions] = useState(tagOptions)
	const [selectedTags, setSelectedTags] = useState([])

	const handleTagChange = (newValue) => {
		setSelectedTags(newValue)
	}

	useEffect(() => {
		const filteredOptions = tagOptions.filter((group) => {
			const groupHasSelectedTag = group.options?.some((option) => selectedTags.some((tag) => tag.value === option.value)) | group.options

			return !groupHasSelectedTag
		})

		setFilteredOptions(filteredOptions)
	}, [selectedTags])

	const handleSubmit = (event) => {
		event.preventDefault()
	}

	const handleFileUploaded = (res) => {
		console.log('handleFileUploaded', res)
		setServerResponse(res.data)
		// setTags('')
		setFile(null)
		queryClient.invalidateQueries('dashboardData')
	}
	const queryClient = useQueryClient()

	return (
		<form onSubmit={handleSubmit} className='max-w-lg mx-auto'>
			<div className='mb-4 mt-8 flex flex-col w-full'>
				<label className='block text-gray-700 font-bold mr-2' htmlFor='tags'>
					Tags
				</label>
				{/* <input
					className='shadow border caret-black appearance-none rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full'
					id='tags'
					type='text'
					placeholder='Enter tags'
					value={tags}
					onChange={(event) => setTags(event.target.value)}
					// required
				/> */}
				<CreatableSelect
					id='tags'
					captureMenuScroll={false}
					// menuShouldScrollIntoView={true}
					// closeMenuOnScroll={true}
					// closeMenuOnSelect={false}
					components={animatedComponents}
					className='text-gray-700 w-full'
					placeholder='Enter tags'
					isMulti
					options={filteredOptions}
					value={selectedTags}
					onChange={handleTagChange}
				/>
			</div>

			<div className='mb-4'>
				<label className='block text-gray-700 font-bold mb-2' htmlFor='picture'>
					Picture
				</label>
				<ImageFileSelector file={file} setFile={setFile} />
			</div>

			<div className='flex items-center justify-between'>
				<UploadButton tags={selectedTags.map((tag) => tag.value)} file={file} onUpload={handleFileUploaded} />
			</div>
			{serverResponse && (
				<div className='flex flex-col items-center justify-between'>
					<p>Google ID: {serverResponse.gid}</p>
					<p>IK ID: {serverResponse.ikid}</p>
					<p>DB ID: {serverResponse.id}</p>
					<div>Tags: {<TagsList tags={serverResponse.tags} />}</div>
					<MyLink to={`/images/${serverResponse.id}`} text={`Go to file`} />
				</div>
			)}
		</form>
	)
}

export default UploadImageForm
